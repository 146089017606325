import { useState, useEffect, useMemo, JSXElementConstructor, Key, ReactElement } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";
import themeDark from "assets/theme-dark";
import themeDarkRTL from "assets/theme-dark/theme-rtl";
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { routes } from "routes";
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator, setCurrentAccount, setCurrentOrganization, setLayout, setCurrentFeatureFlags } from "context";
import brandWhite from "assets/images/QQWIK-logo-512x512.png";
import brandDark from "assets/images/QQWIK-logo-512x512.png";
import { getCookie } from 'typescript-cookie'
import globalSettings  from "context/globals"
import Gravatar from "react-gravatar"
import Helpers from "context/Helpers"

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
    iToken,
    currentFeatureFlags,
    currentAccount
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();
  const [isValidating, setIsValidating] = useState(false);
  const [isNavigatingToSignInPage, setIsNavigatingToSignInPage] = useState(false);
  
  const navigateToLoginPage = () => {
  setLayout(dispatch, "page")
  setIsNavigatingToSignInPage(true)
  window.location.href = `/sign-in?rdurl=${encodeURIComponent(
    window.location.href
  )}`
}

  const validateiToken = () => {
  console.log('validateiToken')
  setIsValidating(false)
  fetch(`${globalSettings.apiBaseUrl}/api/account/retrievemyprofile`, {credentials: "include"})
    .then(Response => Response.json())
    .then(data => {
      if (data?.responseCode !== 1000) {
        navigateToLoginPage()
      } else {
        setCurrentAccount(dispatch, data.content)
        // Once the list of available organizations for the current account is loaded,
        // then set the current organization. NOTE: If there is no 'currentOrganizationId' cookie,
        // then set the current organization to the first organization in the list.
        let currentOrganizationId = data.content.organizations[0].id
        if(getCookie("currentorganizationid")) {
          currentOrganizationId = parseInt(getCookie("currentorganizationid"))
        }
        setCurrentOrganization(dispatch, data.content.organizations?.find((o : any) => o.id === currentOrganizationId))
        setIsValidating(false)
      }
    })
    .catch(err => {
      alert(
        `Problem with ${globalSettings.apiBaseUrl}, is the Imatari API web server running?`
      )
    })
  }

  const retrieveFeatureFlags = () => {
    console.log('App.tsx - retrieveFeatureFlags()')
    fetch(`${globalSettings.apiBaseUrl}/api/featureflag/retrieveall`, {credentials: "include"})
      .then(Response => Response.json())
      .then(data => {
        setCurrentFeatureFlags(dispatch, data.content)
      })
      .catch(err => {
        alert(
          `Problem with ${globalSettings.apiBaseUrl}, is the Imatari API web server running?`
        )
      })
    }

  // Cache for the rtl
  useMemo(() => {
    const pluginRtl: any = rtlPlugin;
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [pluginRtl],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  const getRoutes = (allRoutes: any[]): any =>
    allRoutes.map(
      (route: {
        collapse: any;
        route: string;
        component: ReactElement<any, string | JSXElementConstructor<any>>;
        key: Key;
      }) => {
        if(route.key === "qrcodemanager") {
          console.log(`App.tsx - currentFeatureFlags = ${currentFeatureFlags}`)
          console.log(`App.tsx - currentFeatureFlags JSON.strigify = ${JSON.stringify(currentFeatureFlags)}`)
          console.log(`App.tsx - currentFeatureFlags.QRManager2 = ${currentFeatureFlags.QRManager2}`)
        }
        if (route.collapse) {
          return getRoutes(route.collapse);
        }
        if (route.route) {
          return <Route path={route.route} element={route.component} key={route.key} />;
        }
        return null;
      }
    );

  const routes2 = routes.filter(item => {
    if(currentAccount?.permissions?.includes(item.permissionName)) {
      return item;
    }
    if(item.key === "currentProfile") {
      item.name = currentAccount.firstName || Helpers.TruncateString(currentAccount?.username) || Helpers.TruncateString(currentAccount?.email) || ""
      item.icon = <Gravatar
      email={currentAccount.email}
      //className={classes.avatarImg}
      default="mp"
      size={35}
    />
      return item;
    }
    return null;
  })
  console.log(`routes.length = ${routes.length}, routes2.length = ${routes2.length}`)
  const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="small" color="inherit">
        settings
      </Icon>
    </MDBox>
  );

  // Set the default organization
  useEffect(() => {
    console.log(`App.tsx -- useEffect [], layout = ${layout}`)
    setCurrentOrganization(dispatch, {})
  }, []);

  // Setting the dir attribute for the body element
  useEffect(() => {
    console.log(`App.tsx -- useEffect [direction]`)
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    console.log(`App.tsx -- useEffect [pathname], layout = ${layout}`)
    retrieveFeatureFlags()
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  useEffect(() => {
    console.log("App.tsx -- useEffect() -- step #1")
    // Never validate the itoken on the "anonymous" pages such as signin, signup, or forgot my password
    if (
      window.location.pathname?.indexOf("/sign-out") !== -1 ||
      window.location.pathname?.indexOf("/sign-up") !== -1 ||
      window.location.pathname?.indexOf("/sign-in") !== -1 ||
      window.location.pathname?.indexOf("/authentication/sign-in/basic") !== -1 ||
      window.location.pathname?.indexOf("/reset-password") !== -1 ||
      window.location.pathname?.indexOf("/change-password") !== -1
    ) {
      console.log(`anonymous url of: ${window.location.pathname}`)
    } else {
      console.log(`current pathname requires an itoken cookie check: ${window.location.pathname}`)
      if (!getCookie('itoken')) {
        console.log('did not find itoken cookie, navigating to login page.')
        navigateToLoginPage()
      } else {
        console.log('found itoken cookie')
        validateiToken()
      }
    }
  }, [pathname]);

  return (!isNavigatingToSignInPage && !isValidating &&
    (direction === "rtl" ? (
    <CacheProvider value={rtlCache}>
      <ThemeProvider theme={darkMode ? themeDarkRTL : themeRTL}>
        <CssBaseline />
        {layout === "dashboard" && (
          <>
            <Sidenav
              color={sidenavColor}
              brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
              brandName="Qqwik Portal"
              routes={routes2}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            <Configurator />
            {/*- the very cool floating gear button configsButton*/} 
          </>
        )}
        {layout === "vr" && <Configurator />}
        <Routes>
          {getRoutes(routes)}
          <Route path="*" element={<Navigate to="/dashboards/analytics" />} />
        </Routes>
      </ThemeProvider>
    </CacheProvider>
  ) : 
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      {layout === "dashboard" && (
        <>
          <Sidenav
            color={sidenavColor}
            brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
            brandName="Qwwik Portal"
            routes={routes2}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
          <Configurator />
          {/*- the very cool floating gear button configsButton*/} 
        </>
      )}
      {layout === "vr" && <Configurator />}
      <Routes>
        {getRoutes(routes)}
        <Route path="*" element={<Navigate to="/dashboards/analytics" />} />
      </Routes>
    </ThemeProvider>
  ))
}
