const materialIconsCodePoints = [
{ key:"10k", value: "e951"},
{ key: "10mp", value: "e952"},
{ key: "11mp", value: "e953"},
{ key: "123", value: "eb8d"},
{ key: "12mp", value: "e954"},
{ key: "13mp", value: "e955"},
{ key: "14mp", value: "e956"},
{ key: "15mp", value: "e957"},
{ key: "16mp", value: "e958"},
{ key: "17mp", value: "e959"},
{ key: "18_up_rating", value: "f8fd"},
{ key: "18mp", value: "e95a"},
{ key: "19mp", value: "e95b"},
{ key: "1k", value: "e95c"},
{ key: "1k_plus", value: "e95d"},
{ key: "1x_mobiledata", value: "efcd"},
{ key: "20mp", value: "e95e"},
{ key: "21mp", value: "e95f"},
{ key: "22mp", value: "e960"},
{ key: "23mp", value: "e961"},
{ key: "24mp", value: "e962"},
{ key: "2k", value: "e963"},
{ key: "2k_plus", value: "e964"},
{ key: "2mp", value: "e965"},
{ key: "30fps", value: "efce"},
{ key: "30fps_select", value: "efcf"},
{ key: "360", value: "e577"},
{ key: "3d_rotation", value: "e84d"},
{ key: "3g_mobiledata", value: "efd0"},
{ key: "3k", value: "e966"},
{ key: "3k_plus", value: "e967"},
{ key: "3mp", value: "e968"},
{ key: "3p", value: "efd1"},
{ key: "4g_mobiledata", value: "efd2"},
{ key: "4g_plus_mobiledata", value: "efd3"},
{ key: "4k", value: "e072"},
{ key: "4k_plus", value: "e969"},
{ key: "4mp", value: "e96a"},
{ key: "5g", value: "ef38"},
{ key: "5k", value: "e96b"},
{ key: "5k_plus", value: "e96c"},
{ key: "5mp", value: "e96d"},
{ key: "60fps", value: "efd4"},
{ key: "60fps_select", value: "efd5"},
{ key: "6_ft_apart", value: "f21e"},
{ key: "6k", value: "e96e"},
{ key: "6k_plus", value: "e96f"},
{ key: "6mp", value: "e970"},
{ key: "7k", value: "e971"},
{ key: "7k_plus", value: "e972"},
{ key: "7mp", value: "e973"},
{ key: "8k", value: "e974"},
{ key: "8k_plus", value: "e975"},
{ key: "8mp", value: "e976"},
{ key: "9k", value: "e977"},
{ key: "9k_plus", value: "e978"},
{ key: "9mp", value: "e979"},
{ key: "abc", value: "eb94"},
{ key: "ac_unit", value: "eb3b"},
{ key: "access_alarm", value: "e190"},
{ key: "access_alarms", value: "e191"},
{ key: "access_time", value: "e192"},
{ key: "access_time_filled", value: "efd6"},
{ key: "accessibility", value: "e84e"},
{ key: "accessibility_new", value: "e92c"},
{ key: "accessible", value: "e914"},
{ key: "accessible_forward", value: "e934"},
{ key: "account_balance", value: "e84f"},
{ key: "account_balance_wallet", value: "e850"},
{ key: "account_box", value: "e851"},
{ key: "account_circle", value: "e853"},
{ key: "account_tree", value: "e97a"},
{ key: "ad_units", value: "ef39"},
{ key: "adb", value: "e60e"},
{ key: "add", value: "e145"},
{ key: "add_a_photo", value: "e439"},
{ key: "add_alarm", value: "e193"},
{ key: "add_alert", value: "e003"},
{ key: "add_box", value: "e146"},
{ key: "add_business", value: "e729"},
{ key: "add_call", value: "e0e8"},
{ key: "add_card", value: "eb86"},
{ key: "add_chart", value: "e97b"},
{ key: "add_circle", value: "e147"},
{ key: "add_circle_outline", value: "e148"},
{ key: "add_comment", value: "e266"},
{ key: "add_home", value: "f8eb"},
{ key: "add_home_work", value: "f8ed"},
{ key: "add_ic_call", value: "e97c"},
{ key: "add_link", value: "e178"},
{ key: "add_location", value: "e567"},
{ key: "add_location_alt", value: "ef3a"},
{ key: "add_moderator", value: "e97d"},
{ key: "add_photo_alternate", value: "e43e"},
{ key: "add_reaction", value: "e1d3"},
{ key: "add_road", value: "ef3b"},
{ key: "add_shopping_cart", value: "e854"},
{ key: "add_task", value: "f23a"},
{ key: "add_to_drive", value: "e65c"},
{ key: "add_to_home_screen", value: "e1fe"},
{ key: "add_to_photos", value: "e39d"},
{ key: "add_to_queue", value: "e05c"},
{ key: "addchart", value: "ef3c"},
{ key: "adf_scanner", value: "eada"},
{ key: "adjust", value: "e39e"},
{ key: "admin_panel_settings", value: "ef3d"},
{ key: "adobe", value: "ea96"},
{ key: "ads_click", value: "e762"},
{ key: "agriculture", value: "ea79"},
{ key: "air", value: "efd8"},
{ key: "airline_seat_flat", value: "e630"},
{ key: "airline_seat_flat_angled", value: "e631"},
{ key: "airline_seat_individual_suite", value: "e632"},
{ key: "airline_seat_legroom_extra", value: "e633"},
{ key: "airline_seat_legroom_normal", value: "e634"},
{ key: "airline_seat_legroom_reduced", value: "e635"},
{ key: "airline_seat_recline_extra", value: "e636"},
{ key: "airline_seat_recline_normal", value: "e637"},
{ key: "airline_stops", value: "e7d0"},
{ key: "airlines", value: "e7ca"},
{ key: "airplane_ticket", value: "efd9"},
{ key: "airplanemode_active", value: "e195"},
{ key: "airplanemode_inactive", value: "e194"},
{ key: "airplanemode_off", value: "e194"},
{ key: "airplanemode_on", value: "e195"},
{ key: "airplay", value: "e055"},
{ key: "airport_shuttle", value: "eb3c"},
{ key: "alarm", value: "e855"},
{ key: "alarm_add", value: "e856"},
{ key: "alarm_off", value: "e857"},
{ key: "alarm_on", value: "e858"},
{ key: "album", value: "e019"},
{ key: "align_horizontal_center", value: "e00f"},
{ key: "align_horizontal_left", value: "e00d"},
{ key: "align_horizontal_right", value: "e010"},
{ key: "align_vertical_bottom", value: "e015"},
{ key: "align_vertical_center", value: "e011"},
{ key: "align_vertical_top", value: "e00c"},
{ key: "all_inbox", value: "e97f"},
{ key: "all_inclusive", value: "eb3d"},
{ key: "all_out", value: "e90b"},
{ key: "alt_route", value: "f184"},
{ key: "alternate_email", value: "e0e6"},
{ key: "amp_stories", value: "ea13"},
{ key: "analytics", value: "ef3e"},
{ key: "anchor", value: "f1cd"},
{ key: "android", value: "e859"},
{ key: "animation", value: "e71c"},
{ key: "announcement", value: "e85a"},
{ key: "aod", value: "efda"},
{ key: "apartment", value: "ea40"},
{ key: "api", value: "f1b7"},
{ key: "app_blocking", value: "ef3f"},
{ key: "app_registration", value: "ef40"},
{ key: "app_settings_alt", value: "ef41"},
{ key: "app_shortcut", value: "eae4"},
{ key: "apple", value: "ea80"},
{ key: "approval", value: "e982"},
{ key: "apps", value: "e5c3"},
{ key: "apps_outage", value: "e7cc"},
{ key: "architecture", value: "ea3b"},
{ key: "archive", value: "e149"},
{ key: "area_chart", value: "e770"},
{ key: "arrow_back", value: "e5c4"},
{ key: "arrow_back_ios", value: "e5e0"},
{ key: "arrow_back_ios_new", value: "e2ea"},
{ key: "arrow_circle_down", value: "f181"},
{ key: "arrow_circle_left", value: "eaa7"},
{ key: "arrow_circle_right", value: "eaaa"},
{ key: "arrow_circle_up", value: "f182"},
{ key: "arrow_downward", value: "e5db"},
{ key: "arrow_drop_down", value: "e5c5"},
{ key: "arrow_drop_down_circle", value: "e5c6"},
{ key: "arrow_drop_up", value: "e5c7"},
{ key: "arrow_forward", value: "e5c8"},
{ key: "arrow_forward_ios", value: "e5e1"},
{ key: "arrow_left", value: "e5de"},
{ key: "arrow_outward", value: "f8ce"},
{ key: "arrow_right", value: "e5df"},
{ key: "arrow_right_alt", value: "e941"},
{ key: "arrow_upward", value: "e5d8"},
{ key: "art_track", value: "e060"},
{ key: "article", value: "ef42"},
{ key: "aspect_ratio", value: "e85b"},
{ key: "assessment", value: "e85c"},
{ key: "assignment", value: "e85d"},
{ key: "assignment_add", value: "f848"},
{ key: "assignment_ind", value: "e85e"},
{ key: "assignment_late", value: "e85f"},
{ key: "assignment_return", value: "e860"},
{ key: "assignment_returned", value: "e861"},
{ key: "assignment_turned_in", value: "e862"},
{ key: "assist_walker", value: "f8d5"},
{ key: "assistant", value: "e39f"},
{ key: "assistant_direction", value: "e988"},
{ key: "assistant_navigation", value: "e989"},
{ key: "assistant_photo", value: "e3a0"},
{ key: "assured_workload", value: "eb6f"},
{ key: "atm", value: "e573"},
{ key: "attach_email", value: "ea5e"},
{ key: "attach_file", value: "e226"},
{ key: "attach_money", value: "e227"},
{ key: "attachment", value: "e2bc"},
{ key: "attractions", value: "ea52"},
{ key: "attribution", value: "efdb"},
{ key: "audio_file", value: "eb82"},
{ key: "audiotrack", value: "e3a1"},
{ key: "auto_awesome", value: "e65f"},
{ key: "auto_awesome_mosaic", value: "e660"},
{ key: "auto_awesome_motion", value: "e661"},
{ key: "auto_delete", value: "ea4c"},
{ key: "auto_fix_high", value: "e663"},
{ key: "auto_fix_normal", value: "e664"},
{ key: "auto_fix_off", value: "e665"},
{ key: "auto_graph", value: "e4fb"},
{ key: "auto_mode", value: "ec20"},
{ key: "auto_stories", value: "e666"},
{ key: "autofps_select", value: "efdc"},
{ key: "autorenew", value: "e863"},
{ key: "av_timer", value: "e01b"},
{ key: "baby_changing_station", value: "f19b"},
{ key: "back_hand", value: "e764"},
{ key: "backpack", value: "f19c"},
{ key: "backspace", value: "e14a"},
{ key: "backup", value: "e864"},
{ key: "backup_table", value: "ef43"},
{ key: "badge", value: "ea67"},
{ key: "bakery_dining", value: "ea53"},
{ key: "balance", value: "eaf6"},
{ key: "balcony", value: "e58f"},
{ key: "ballot", value: "e172"},
{ key: "bar_chart", value: "e26b"},
{ key: "barcode_reader", value: "f85c"},
{ key: "batch_prediction", value: "f0f5"},
{ key: "bathroom", value: "efdd"},
{ key: "bathtub", value: "ea41"},
{ key: "battery_0_bar", value: "ebdc"},
{ key: "battery_1_bar", value: "ebd9"},
{ key: "battery_2_bar", value: "ebe0"},
{ key: "battery_3_bar", value: "ebdd"},
{ key: "battery_4_bar", value: "ebe2"},
{ key: "battery_5_bar", value: "ebd4"},
{ key: "battery_6_bar", value: "ebd2"},
{ key: "battery_alert", value: "e19c"},
{ key: "battery_charging_full", value: "e1a3"},
{ key: "battery_full", value: "e1a4"},
{ key: "battery_saver", value: "efde"},
{ key: "battery_std", value: "e1a5"},
{ key: "battery_unknown", value: "e1a6"},
{ key: "beach_access", value: "eb3e"},
{ key: "bed", value: "efdf"},
{ key: "bedroom_baby", value: "efe0"},
{ key: "bedroom_child", value: "efe1"},
{ key: "bedroom_parent", value: "efe2"},
{ key: "bedtime", value: "ef44"},
{ key: "bedtime_off", value: "eb76"},
{ key: "beenhere", value: "e52d"},
{ key: "bento", value: "f1f4"},
{ key: "bike_scooter", value: "ef45"},
{ key: "biotech", value: "ea3a"},
{ key: "blender", value: "efe3"},
{ key: "blind", value: "f8d6"},
{ key: "blinds", value: "e286"},
{ key: "blinds_closed", value: "ec1f"},
{ key: "block", value: "e14b"},
{ key: "block_flipped", value: "ef46"},
{ key: "bloodtype", value: "efe4"},
{ key: "bluetooth", value: "e1a7"},
{ key: "bluetooth_audio", value: "e60f"},
{ key: "bluetooth_connected", value: "e1a8"},
{ key: "bluetooth_disabled", value: "e1a9"},
{ key: "bluetooth_drive", value: "efe5"},
{ key: "bluetooth_searching", value: "e1aa"},
{ key: "blur_circular", value: "e3a2"},
{ key: "blur_linear", value: "e3a3"},
{ key: "blur_off", value: "e3a4"},
{ key: "blur_on", value: "e3a5"},
{ key: "bolt", value: "ea0b"},
{ key: "book", value: "e865"},
{ key: "book_online", value: "f217"},
{ key: "bookmark", value: "e866"},
{ key: "bookmark_add", value: "e598"},
{ key: "bookmark_added", value: "e599"},
{ key: "bookmark_border", value: "e867"},
{ key: "bookmark_outline", value: "e867"},
{ key: "bookmark_remove", value: "e59a"},
{ key: "bookmarks", value: "e98b"},
{ key: "border_all", value: "e228"},
{ key: "border_bottom", value: "e229"},
{ key: "border_clear", value: "e22a"},
{ key: "border_color", value: "e22b"},
{ key: "border_horizontal", value: "e22c"},
{ key: "border_inner", value: "e22d"},
{ key: "border_left", value: "e22e"},
{ key: "border_outer", value: "e22f"},
{ key: "border_right", value: "e230"},
{ key: "border_style", value: "e231"},
{ key: "border_top", value: "e232"},
{ key: "border_vertical", value: "e233"},
{ key: "boy", value: "eb67"},
{ key: "branding_watermark", value: "e06b"},
{ key: "breakfast_dining", value: "ea54"},
{ key: "brightness_1", value: "e3a6"},
{ key: "brightness_2", value: "e3a7"},
{ key: "brightness_3", value: "e3a8"},
{ key: "brightness_4", value: "e3a9"},
{ key: "brightness_5", value: "e3aa"},
{ key: "brightness_6", value: "e3ab"},
{ key: "brightness_7", value: "e3ac"},
{ key: "brightness_auto", value: "e1ab"},
{ key: "brightness_high", value: "e1ac"},
{ key: "brightness_low", value: "e1ad"},
{ key: "brightness_medium", value: "e1ae"},
{ key: "broadcast_on_home", value: "f8f8"},
{ key: "broadcast_on_personal", value: "f8f9"},
{ key: "broken_image", value: "e3ad"},
{ key: "browse_gallery", value: "ebd1"},
{ key: "browser_not_supported", value: "ef47"},
{ key: "browser_updated", value: "e7cf"},
{ key: "brunch_dining", value: "ea73"},
{ key: "brush", value: "e3ae"},
{ key: "bubble_chart", value: "e6dd"},
{ key: "bug_report", value: "e868"},
{ key: "build", value: "e869"},
{ key: "build_circle", value: "ef48"},
{ key: "bungalow", value: "e591"},
{ key: "burst_mode", value: "e43c"},
{ key: "bus_alert", value: "e98f"},
{ key: "business", value: "e0af"},
{ key: "business_center", value: "eb3f"},
{ key: "cabin", value: "e589"},
{ key: "cable", value: "efe6"},
{ key: "cached", value: "e86a"},
{ key: "cake", value: "e7e9"},
{ key: "calculate", value: "ea5f"},
{ key: "calendar_month", value: "ebcc"},
{ key: "calendar_today", value: "e935"},
{ key: "calendar_view_day", value: "e936"},
{ key: "calendar_view_month", value: "efe7"},
{ key: "calendar_view_week", value: "efe8"},
{ key: "call", value: "e0b0"},
{ key: "call_end", value: "e0b1"},
{ key: "call_made", value: "e0b2"},
{ key: "call_merge", value: "e0b3"},
{ key: "call_missed", value: "e0b4"},
{ key: "call_missed_outgoing", value: "e0e4"},
{ key: "call_received", value: "e0b5"},
{ key: "call_split", value: "e0b6"},
{ key: "call_to_action", value: "e06c"},
{ key: "camera", value: "e3af"},
{ key: "camera_alt", value: "e3b0"},
{ key: "camera_enhance", value: "e8fc"},
{ key: "camera_front", value: "e3b1"},
{ key: "camera_indoor", value: "efe9"},
{ key: "camera_outdoor", value: "efea"},
{ key: "camera_rear", value: "e3b2"},
{ key: "camera_roll", value: "e3b3"},
{ key: "cameraswitch", value: "efeb"},
{ key: "campaign", value: "ef49"},
{ key: "cancel", value: "e5c9"},
{ key: "cancel_presentation", value: "e0e9"},
{ key: "cancel_schedule_send", value: "ea39"},
{ key: "candlestick_chart", value: "ead4"},
{ key: "car_crash", value: "ebf2"},
{ key: "car_rental", value: "ea55"},
{ key: "car_repair", value: "ea56"},
{ key: "card_giftcard", value: "e8f6"},
{ key: "card_membership", value: "e8f7"},
{ key: "card_travel", value: "e8f8"},
{ key: "carpenter", value: "f1f8"},
{ key: "cases", value: "e992"},
{ key: "casino", value: "eb40"},
{ key: "cast", value: "e307"},
{ key: "cast_connected", value: "e308"},
{ key: "cast_for_education", value: "efec"},
{ key: "castle", value: "eab1"},
{ key: "catching_pokemon", value: "e508"},
{ key: "category", value: "e574"},
{ key: "celebration", value: "ea65"},
{ key: "cell_tower", value: "ebba"},
{ key: "cell_wifi", value: "e0ec"},
{ key: "center_focus_strong", value: "e3b4"},
{ key: "center_focus_weak", value: "e3b5"},
{ key: "chair", value: "efed"},
{ key: "chair_alt", value: "efee"},
{ key: "chalet", value: "e585"},
{ key: "change_circle", value: "e2e7"},
{ key: "change_history", value: "e86b"},
{ key: "charging_station", value: "f19d"},
{ key: "chat", value: "e0b7"},
{ key: "chat_bubble", value: "e0ca"},
{ key: "chat_bubble_outline", value: "e0cb"},
{ key: "check", value: "e5ca"},
{ key: "check_box", value: "e834"},
{ key: "check_box_outline_blank", value: "e835"},
{ key: "check_circle", value: "e86c"},
{ key: "check_circle_outline", value: "e92d"},
{ key: "checklist", value: "e6b1"},
{ key: "checklist_rtl", value: "e6b3"},
{ key: "checkroom", value: "f19e"},
{ key: "chevron_left", value: "e5cb"},
{ key: "chevron_right", value: "e5cc"},
{ key: "child_care", value: "eb41"},
{ key: "child_friendly", value: "eb42"},
{ key: "chrome_reader_mode", value: "e86d"},
{ key: "church", value: "eaae"},
{ key: "circle", value: "ef4a"},
{ key: "circle_notifications", value: "e994"},
{ key: "class", value: "e86e"},
{ key: "clean_hands", value: "f21f"},
{ key: "cleaning_services", value: "f0ff"},
{ key: "clear", value: "e14c"},
{ key: "clear_all", value: "e0b8"},
{ key: "close", value: "e5cd"},
{ key: "close_fullscreen", value: "f1cf"},
{ key: "closed_caption", value: "e01c"},
{ key: "closed_caption_disabled", value: "f1dc"},
{ key: "closed_caption_off", value: "e996"},
{ key: "cloud", value: "e2bd"},
{ key: "cloud_circle", value: "e2be"},
{ key: "cloud_done", value: "e2bf"},
{ key: "cloud_download", value: "e2c0"},
{ key: "cloud_off", value: "e2c1"},
{ key: "cloud_queue", value: "e2c2"},
{ key: "cloud_sync", value: "eb5a"},
{ key: "cloud_upload", value: "e2c3"},
{ key: "cloudy_snowing", value: "e810"},
{ key: "co2", value: "e7b0"},
{ key: "co_present", value: "eaf0"},
{ key: "code", value: "e86f"},
{ key: "code_off", value: "e4f3"},
{ key: "coffee", value: "efef"},
{ key: "coffee_maker", value: "eff0"},
{ key: "collections", value: "e3b6"},
{ key: "collections_bookmark", value: "e431"},
{ key: "color_lens", value: "e3b7"},
{ key: "colorize", value: "e3b8"},
{ key: "comment", value: "e0b9"},
{ key: "comment_bank", value: "ea4e"},
{ key: "comments_disabled", value: "e7a2"},
{ key: "commit", value: "eaf5"},
{ key: "commute", value: "e940"},
{ key: "compare", value: "e3b9"},
{ key: "compare_arrows", value: "e915"},
{ key: "compass_calibration", value: "e57c"},
{ key: "compost", value: "e761"},
{ key: "compress", value: "e94d"},
{ key: "computer", value: "e30a"},
{ key: "confirmation_number", value: "e638"},
{ key: "connect_without_contact", value: "f223"},
{ key: "connected_tv", value: "e998"},
{ key: "connecting_airports", value: "e7c9"},
{ key: "construction", value: "ea3c"},
{ key: "contact_emergency", value: "f8d1"},
{ key: "contact_mail", value: "e0d0"},
{ key: "contact_page", value: "f22e"},
{ key: "contact_phone", value: "e0cf"},
{ key: "contact_support", value: "e94c"},
{ key: "contactless", value: "ea71"},
{ key: "contacts", value: "e0ba"},
{ key: "content_copy", value: "e14d"},
{ key: "content_cut", value: "e14e"},
{ key: "content_paste", value: "e14f"},
{ key: "content_paste_go", value: "ea8e"},
{ key: "content_paste_off", value: "e4f8"},
{ key: "content_paste_search", value: "ea9b"},
{ key: "contrast", value: "eb37"},
{ key: "control_camera", value: "e074"},
{ key: "control_point", value: "e3ba"},
{ key: "control_point_duplicate", value: "e3bb"},
{ key: "conveyor_belt", value: "f867"},
{ key: "cookie", value: "eaac"},
{ key: "copy_all", value: "e2ec"},
{ key: "copyright", value: "e90c"},
{ key: "coronavirus", value: "f221"},
{ key: "corporate_fare", value: "f1d0"},
{ key: "cottage", value: "e587"},
{ key: "countertops", value: "f1f7"},
{ key: "create", value: "e150"},
{ key: "create_new_folder", value: "e2cc"},
{ key: "credit_card", value: "e870"},
{ key: "credit_card_off", value: "e4f4"},
{ key: "credit_score", value: "eff1"},
{ key: "crib", value: "e588"},
{ key: "crisis_alert", value: "ebe9"},
{ key: "crop", value: "e3be"},
{ key: "crop_16_9", value: "e3bc"},
{ key: "crop_3_2", value: "e3bd"},
{ key: "crop_5_4", value: "e3bf"},
{ key: "crop_7_5", value: "e3c0"},
{ key: "crop_din", value: "e3c1"},
{ key: "crop_free", value: "e3c2"},
{ key: "crop_landscape", value: "e3c3"},
{ key: "crop_original", value: "e3c4"},
{ key: "crop_portrait", value: "e3c5"},
{ key: "crop_rotate", value: "e437"},
{ key: "crop_square", value: "e3c6"},
{ key: "cruelty_free", value: "e799"},
{ key: "css", value: "eb93"},
{ key: "currency_bitcoin", value: "ebc5"},
{ key: "currency_exchange", value: "eb70"},
{ key: "currency_franc", value: "eafa"},
{ key: "currency_lira", value: "eaef"},
{ key: "currency_pound", value: "eaf1"},
{ key: "currency_ruble", value: "eaec"},
{ key: "currency_rupee", value: "eaf7"},
{ key: "currency_yen", value: "eafb"},
{ key: "currency_yuan", value: "eaf9"},
{ key: "curtains", value: "ec1e"},
{ key: "curtains_closed", value: "ec1d"},
{ key: "cyclone", value: "ebd5"},
{ key: "dangerous", value: "e99a"},
{ key: "dark_mode", value: "e51c"},
{ key: "dashboard", value: "e871"},
{ key: "dashboard_customize", value: "e99b"},
{ key: "data_array", value: "ead1"},
{ key: "data_exploration", value: "e76f"},
{ key: "data_object", value: "ead3"},
{ key: "data_saver_off", value: "eff2"},
{ key: "data_saver_on", value: "eff3"},
{ key: "data_thresholding", value: "eb9f"},
{ key: "data_usage", value: "e1af"},
{ key: "dataset", value: "f8ee"},
{ key: "dataset_linked", value: "f8ef"},
{ key: "date_range", value: "e916"},
{ key: "deblur", value: "eb77"},
{ key: "deck", value: "ea42"},
{ key: "dehaze", value: "e3c7"},
{ key: "delete", value: "e872"},
{ key: "delete_forever", value: "e92b"},
{ key: "delete_outline", value: "e92e"},
{ key: "delete_sweep", value: "e16c"},
{ key: "delivery_dining", value: "ea72"},
{ key: "density_large", value: "eba9"},
{ key: "density_medium", value: "eb9e"},
{ key: "density_small", value: "eba8"},
{ key: "departure_board", value: "e576"},
{ key: "description", value: "e873"},
{ key: "deselect", value: "ebb6"},
{ key: "design_services", value: "f10a"},
{ key: "desk", value: "f8f4"},
{ key: "desktop_access_disabled", value: "e99d"},
{ key: "desktop_mac", value: "e30b"},
{ key: "desktop_windows", value: "e30c"},
{ key: "details", value: "e3c8"},
{ key: "developer_board", value: "e30d"},
{ key: "developer_board_off", value: "e4ff"},
{ key: "developer_mode", value: "e1b0"},
{ key: "device_hub", value: "e335"},
{ key: "device_thermostat", value: "e1ff"},
{ key: "device_unknown", value: "e339"},
{ key: "devices", value: "e1b1"},
{ key: "devices_fold", value: "ebde"},
{ key: "devices_other", value: "e337"},
{ key: "dew_point", value: "f879"},
{ key: "dialer_sip", value: "e0bb"},
{ key: "dialpad", value: "e0bc"},
{ key: "diamond", value: "ead5"},
{ key: "difference", value: "eb7d"},
{ key: "dining", value: "eff4"},
{ key: "dinner_dining", value: "ea57"},
{ key: "directions", value: "e52e"},
{ key: "directions_bike", value: "e52f"},
{ key: "directions_boat", value: "e532"},
{ key: "directions_boat_filled", value: "eff5"},
{ key: "directions_bus", value: "e530"},
{ key: "directions_bus_filled", value: "eff6"},
{ key: "directions_car", value: "e531"},
{ key: "directions_car_filled", value: "eff7"},
{ key: "directions_ferry", value: "e532"},
{ key: "directions_off", value: "f10f"},
{ key: "directions_railway", value: "e534"},
{ key: "directions_railway_filled", value: "eff8"},
{ key: "directions_run", value: "e566"},
{ key: "directions_subway", value: "e533"},
{ key: "directions_subway_filled", value: "eff9"},
{ key: "directions_train", value: "e534"},
{ key: "directions_transit", value: "e535"},
{ key: "directions_transit_filled", value: "effa"},
{ key: "directions_walk", value: "e536"},
{ key: "dirty_lens", value: "ef4b"},
{ key: "disabled_by_default", value: "f230"},
{ key: "disabled_visible", value: "e76e"},
{ key: "disc_full", value: "e610"},
{ key: "discord", value: "ea6c"},
{ key: "discount", value: "ebc9"},
{ key: "display_settings", value: "eb97"},
{ key: "diversity_1", value: "f8d7"},
{ key: "diversity_2", value: "f8d8"},
{ key: "diversity_3", value: "f8d9"},
{ key: "dnd_forwardslash", value: "e611"},
{ key: "dns", value: "e875"},
{ key: "do_disturb", value: "f08c"},
{ key: "do_disturb_alt", value: "f08d"},
{ key: "do_disturb_off", value: "f08e"},
{ key: "do_disturb_on", value: "f08f"},
{ key: "do_not_disturb", value: "e612"},
{ key: "do_not_disturb_alt", value: "e611"},
{ key: "do_not_disturb_off", value: "e643"},
{ key: "do_not_disturb_on", value: "e644"},
{ key: "do_not_disturb_on_total_silence", value: "effb"},
{ key: "do_not_step", value: "f19f"},
{ key: "do_not_touch", value: "f1b0"},
{ key: "dock", value: "e30e"},
{ key: "document_scanner", value: "e5fa"},
{ key: "domain", value: "e7ee"},
{ key: "domain_add", value: "eb62"},
{ key: "domain_disabled", value: "e0ef"},
{ key: "domain_verification", value: "ef4c"},
{ key: "done", value: "e876"},
{ key: "done_all", value: "e877"},
{ key: "done_outline", value: "e92f"},
{ key: "donut_large", value: "e917"},
{ key: "donut_small", value: "e918"},
{ key: "door_back", value: "effc"},
{ key: "door_front", value: "effd"},
{ key: "door_sliding", value: "effe"},
{ key: "doorbell", value: "efff"},
{ key: "double_arrow", value: "ea50"},
{ key: "downhill_skiing", value: "e509"},
{ key: "download", value: "f090"},
{ key: "download_done", value: "f091"},
{ key: "download_for_offline", value: "f000"},
{ key: "downloading", value: "f001"},
{ key: "drafts", value: "e151"},
{ key: "drag_handle", value: "e25d"},
{ key: "drag_indicator", value: "e945"},
{ key: "draw", value: "e746"},
{ key: "drive_eta", value: "e613"},
{ key: "drive_file_move", value: "e675"},
{ key: "drive_file_move_outline", value: "e9a1"},
{ key: "drive_file_move_rtl", value: "e76d"},
{ key: "drive_file_rename_outline", value: "e9a2"},
{ key: "drive_folder_upload", value: "e9a3"},
{ key: "dry", value: "f1b3"},
{ key: "dry_cleaning", value: "ea58"},
{ key: "duo", value: "e9a5"},
{ key: "dvr", value: "e1b2"},
{ key: "dynamic_feed", value: "ea14"},
{ key: "dynamic_form", value: "f1bf"},
{ key: "e_mobiledata", value: "f002"},
{ key: "earbuds", value: "f003"},
{ key: "earbuds_battery", value: "f004"},
{ key: "east", value: "f1df"},
{ key: "eco", value: "ea35"},
{ key: "edgesensor_high", value: "f005"},
{ key: "edgesensor_low", value: "f006"},
{ key: "edit", value: "e3c9"},
{ key: "edit_attributes", value: "e578"},
{ key: "edit_calendar", value: "e742"},
{ key: "edit_document", value: "f88c"},
{ key: "edit_location", value: "e568"},
{ key: "edit_location_alt", value: "e1c5"},
{ key: "edit_note", value: "e745"},
{ key: "edit_notifications", value: "e525"},
{ key: "edit_off", value: "e950"},
{ key: "edit_road", value: "ef4d"},
{ key: "edit_square", value: "f88d"},
{ key: "egg", value: "eacc"},
{ key: "egg_alt", value: "eac8"},
{ key: "eject", value: "e8fb"},
{ key: "elderly", value: "f21a"},
{ key: "elderly_woman", value: "eb69"},
{ key: "electric_bike", value: "eb1b"},
{ key: "electric_bolt", value: "ec1c"},
{ key: "electric_car", value: "eb1c"},
{ key: "electric_meter", value: "ec1b"},
{ key: "electric_moped", value: "eb1d"},
{ key: "electric_rickshaw", value: "eb1e"},
{ key: "electric_scooter", value: "eb1f"},
{ key: "electrical_services", value: "f102"},
{ key: "elevator", value: "f1a0"},
{ key: "email", value: "e0be"},
{ key: "emergency", value: "e1eb"},
{ key: "emergency_recording", value: "ebf4"},
{ key: "emergency_share", value: "ebf6"},
{ key: "emoji_emotions", value: "ea22"},
{ key: "emoji_events", value: "ea23"},
{ key: "emoji_flags", value: "ea1a"},
{ key: "emoji_food_beverage", value: "ea1b"},
{ key: "emoji_nature", value: "ea1c"},
{ key: "emoji_objects", value: "ea24"},
{ key: "emoji_people", value: "ea1d"},
{ key: "emoji_symbols", value: "ea1e"},
{ key: "emoji_transportation", value: "ea1f"},
{ key: "energy_savings_leaf", value: "ec1a"},
{ key: "engineering", value: "ea3d"},
{ key: "enhance_photo_translate", value: "e8fc"},
{ key: "enhanced_encryption", value: "e63f"},
{ key: "equalizer", value: "e01d"},
{ key: "error", value: "e000"},
{ key: "error_outline", value: "e001"},
{ key: "escalator", value: "f1a1"},
{ key: "escalator_warning", value: "f1ac"},
{ key: "euro", value: "ea15"},
{ key: "euro_symbol", value: "e926"},
{ key: "ev_station", value: "e56d"},
{ key: "event", value: "e878"},
{ key: "event_available", value: "e614"},
{ key: "event_busy", value: "e615"},
{ key: "event_note", value: "e616"},
{ key: "event_repeat", value: "eb7b"},
{ key: "event_seat", value: "e903"},
{ key: "exit_to_app", value: "e879"},
{ key: "expand", value: "e94f"},
{ key: "expand_circle_down", value: "e7cd"},
{ key: "expand_less", value: "e5ce"},
{ key: "expand_more", value: "e5cf"},
{ key: "explicit", value: "e01e"},
{ key: "explore", value: "e87a"},
{ key: "explore_off", value: "e9a8"},
{ key: "exposure", value: "e3ca"},
{ key: "exposure_minus_1", value: "e3cb"},
{ key: "exposure_minus_2", value: "e3cc"},
{ key: "exposure_neg_1", value: "e3cb"},
{ key: "exposure_neg_2", value: "e3cc"},
{ key: "exposure_plus_1", value: "e3cd"},
{ key: "exposure_plus_2", value: "e3ce"},
{ key: "exposure_zero", value: "e3cf"},
{ key: "extension", value: "e87b"},
{ key: "extension_off", value: "e4f5"},
{ key: "face", value: "e87c"},
{ key: "face_2", value: "f8da"},
{ key: "face_3", value: "f8db"},
{ key: "face_4", value: "f8dc"},
{ key: "face_5", value: "f8dd"},
{ key: "face_6", value: "f8de"},
{ key: "face_retouching_natural", value: "ef4e"},
{ key: "face_retouching_off", value: "f007"},
{ key: "facebook", value: "f234"},
{ key: "fact_check", value: "f0c5"},
{ key: "factory", value: "ebbc"},
{ key: "family_restroom", value: "f1a2"},
{ key: "fast_forward", value: "e01f"},
{ key: "fast_rewind", value: "e020"},
{ key: "fastfood", value: "e57a"},
{ key: "favorite", value: "e87d"},
{ key: "favorite_border", value: "e87e"},
{ key: "favorite_outline", value: "e87e"},
{ key: "fax", value: "ead8"},
{ key: "featured_play_list", value: "e06d"},
{ key: "featured_video", value: "e06e"},
{ key: "feed", value: "f009"},
{ key: "feedback", value: "e87f"},
{ key: "female", value: "e590"},
{ key: "fence", value: "f1f6"},
{ key: "festival", value: "ea68"},
{ key: "fiber_dvr", value: "e05d"},
{ key: "fiber_manual_record", value: "e061"},
{ key: "fiber_new", value: "e05e"},
{ key: "fiber_pin", value: "e06a"},
{ key: "fiber_smart_record", value: "e062"},
{ key: "file_copy", value: "e173"},
{ key: "file_download", value: "e2c4"},
{ key: "file_download_done", value: "e9aa"},
{ key: "file_download_off", value: "e4fe"},
{ key: "file_open", value: "eaf3"},
{ key: "file_present", value: "ea0e"},
{ key: "file_upload", value: "e2c6"},
{ key: "file_upload_off", value: "f886"},
{ key: "filter", value: "e3d3"},
{ key: "filter_1", value: "e3d0"},
{ key: "filter_2", value: "e3d1"},
{ key: "filter_3", value: "e3d2"},
{ key: "filter_4", value: "e3d4"},
{ key: "filter_5", value: "e3d5"},
{ key: "filter_6", value: "e3d6"},
{ key: "filter_7", value: "e3d7"},
{ key: "filter_8", value: "e3d8"},
{ key: "filter_9", value: "e3d9"},
{ key: "filter_9_plus", value: "e3da"},
{ key: "filter_alt", value: "ef4f"},
{ key: "filter_alt_off", value: "eb32"},
{ key: "filter_b_and_w", value: "e3db"},
{ key: "filter_center_focus", value: "e3dc"},
{ key: "filter_drama", value: "e3dd"},
{ key: "filter_frames", value: "e3de"},
{ key: "filter_hdr", value: "e3df"},
{ key: "filter_list", value: "e152"},
{ key: "filter_list_alt", value: "e94e"},
{ key: "filter_list_off", value: "eb57"},
{ key: "filter_none", value: "e3e0"},
{ key: "filter_tilt_shift", value: "e3e2"},
{ key: "filter_vintage", value: "e3e3"},
{ key: "find_in_page", value: "e880"},
{ key: "find_replace", value: "e881"},
{ key: "fingerprint", value: "e90d"},
{ key: "fire_extinguisher", value: "f1d8"},
{ key: "fire_hydrant", value: "f1a3"},
{ key: "fire_hydrant_alt", value: "f8f1"},
{ key: "fire_truck", value: "f8f2"},
{ key: "fireplace", value: "ea43"},
{ key: "first_page", value: "e5dc"},
{ key: "fit_screen", value: "ea10"},
{ key: "fitbit", value: "e82b"},
{ key: "fitness_center", value: "eb43"},
{ key: "flag", value: "e153"},
{ key: "flag_circle", value: "eaf8"},
{ key: "flaky", value: "ef50"},
{ key: "flare", value: "e3e4"},
{ key: "flash_auto", value: "e3e5"},
{ key: "flash_off", value: "e3e6"},
{ key: "flash_on", value: "e3e7"},
{ key: "flashlight_off", value: "f00a"},
{ key: "flashlight_on", value: "f00b"},
{ key: "flatware", value: "f00c"},
{ key: "flight", value: "e539"},
{ key: "flight_class", value: "e7cb"},
{ key: "flight_land", value: "e904"},
{ key: "flight_takeoff", value: "e905"},
{ key: "flip", value: "e3e8"},
{ key: "flip_camera_android", value: "ea37"},
{ key: "flip_camera_ios", value: "ea38"},
{ key: "flip_to_back", value: "e882"},
{ key: "flip_to_front", value: "e883"},
{ key: "flood", value: "ebe6"},
{ key: "flourescent", value: "ec31"},
{ key: "flourescent", value: "f00d"},
{ key: "fluorescent", value: "ec31"},
{ key: "flutter_dash", value: "e00b"},
{ key: "fmd_bad", value: "f00e"},
{ key: "fmd_good", value: "f00f"},
{ key: "foggy", value: "e818"},
{ key: "folder", value: "e2c7"},
{ key: "folder_copy", value: "ebbd"},
{ key: "folder_delete", value: "eb34"},
{ key: "folder_off", value: "eb83"},
{ key: "folder_open", value: "e2c8"},
{ key: "folder_shared", value: "e2c9"},
{ key: "folder_special", value: "e617"},
{ key: "folder_zip", value: "eb2c"},
{ key: "follow_the_signs", value: "f222"},
{ key: "font_download", value: "e167"},
{ key: "font_download_off", value: "e4f9"},
{ key: "food_bank", value: "f1f2"},
{ key: "forest", value: "ea99"},
{ key: "fork_left", value: "eba0"},
{ key: "fork_right", value: "ebac"},
{ key: "forklift", value: "f868"},
{ key: "format_align_center", value: "e234"},
{ key: "format_align_justify", value: "e235"},
{ key: "format_align_left", value: "e236"},
{ key: "format_align_right", value: "e237"},
{ key: "format_bold", value: "e238"},
{ key: "format_clear", value: "e239"},
{ key: "format_color_fill", value: "e23a"},
{ key: "format_color_reset", value: "e23b"},
{ key: "format_color_text", value: "e23c"},
{ key: "format_indent_decrease", value: "e23d"},
{ key: "format_indent_increase", value: "e23e"},
{ key: "format_italic", value: "e23f"},
{ key: "format_line_spacing", value: "e240"},
{ key: "format_list_bulleted", value: "e241"},
{ key: "format_list_bulleted_add", value: "f849"},
{ key: "format_list_numbered", value: "e242"},
{ key: "format_list_numbered_rtl", value: "e267"},
{ key: "format_overline", value: "eb65"},
{ key: "format_paint", value: "e243"},
{ key: "format_quote", value: "e244"},
{ key: "format_shapes", value: "e25e"},
{ key: "format_size", value: "e245"},
{ key: "format_strikethrough", value: "e246"},
{ key: "format_textdirection_l_to_r", value: "e247"},
{ key: "format_textdirection_r_to_l", value: "e248"},
{ key: "format_underline", value: "e249"},
{ key: "format_underlined", value: "e249"},
{ key: "fort", value: "eaad"},
{ key: "forum", value: "e0bf"},
{ key: "forward", value: "e154"},
{ key: "forward_10", value: "e056"},
{ key: "forward_30", value: "e057"},
{ key: "forward_5", value: "e058"},
{ key: "forward_to_inbox", value: "f187"},
{ key: "foundation", value: "f200"},
{ key: "free_breakfast", value: "eb44"},
{ key: "free_cancellation", value: "e748"},
{ key: "front_hand", value: "e769"},
{ key: "front_loader", value: "f869"},
{ key: "fullscreen", value: "e5d0"},
{ key: "fullscreen_exit", value: "e5d1"},
{ key: "functions", value: "e24a"},
{ key: "g_mobiledata", value: "f010"},
{ key: "g_translate", value: "e927"},
{ key: "gamepad", value: "e30f"},
{ key: "games", value: "e021"},
{ key: "garage", value: "f011"},
{ key: "gas_meter", value: "ec19"},
{ key: "gavel", value: "e90e"},
{ key: "generating_tokens", value: "e749"},
{ key: "gesture", value: "e155"},
{ key: "get_app", value: "e884"},
{ key: "gif", value: "e908"},
{ key: "gif_box", value: "e7a3"},
{ key: "girl", value: "eb68"},
{ key: "gite", value: "e58b"},
{ key: "goat", value: "10fffd"},
{ key: "golf_course", value: "eb45"},
{ key: "gpp_bad", value: "f012"},
{ key: "gpp_good", value: "f013"},
{ key: "gpp_maybe", value: "f014"},
{ key: "gps_fixed", value: "e1b3"},
{ key: "gps_not_fixed", value: "e1b4"},
{ key: "gps_off", value: "e1b5"},
{ key: "grade", value: "e885"},
{ key: "gradient", value: "e3e9"},
{ key: "grading", value: "ea4f"},
{ key: "grain", value: "e3ea"},
{ key: "graphic_eq", value: "e1b8"},
{ key: "grass", value: "f205"},
{ key: "grid_3x3", value: "f015"},
{ key: "grid_4x4", value: "f016"},
{ key: "grid_goldenratio", value: "f017"},
{ key: "grid_off", value: "e3eb"},
{ key: "grid_on", value: "e3ec"},
{ key: "grid_view", value: "e9b0"},
{ key: "group", value: "e7ef"},
{ key: "group_add", value: "e7f0"},
{ key: "group_off", value: "e747"},
{ key: "group_remove", value: "e7ad"},
{ key: "group_work", value: "e886"},
{ key: "groups", value: "f233"},
{ key: "groups_2", value: "f8df"},
{ key: "groups_3", value: "f8e0"},
{ key: "h_mobiledata", value: "f018"},
{ key: "h_plus_mobiledata", value: "f019"},
{ key: "hail", value: "e9b1"},
{ key: "handshake", value: "ebcb"},
{ key: "handyman", value: "f10b"},
{ key: "hardware", value: "ea59"},
{ key: "hd", value: "e052"},
{ key: "hdr_auto", value: "f01a"},
{ key: "hdr_auto_select", value: "f01b"},
{ key: "hdr_enhanced_select", value: "ef51"},
{ key: "hdr_off", value: "e3ed"},
{ key: "hdr_off_select", value: "f01c"},
{ key: "hdr_on", value: "e3ee"},
{ key: "hdr_on_select", value: "f01d"},
{ key: "hdr_plus", value: "f01e"},
{ key: "hdr_strong", value: "e3f1"},
{ key: "hdr_weak", value: "e3f2"},
{ key: "headphones", value: "f01f"},
{ key: "headphones_battery", value: "f020"},
{ key: "headset", value: "e310"},
{ key: "headset_mic", value: "e311"},
{ key: "headset_off", value: "e33a"},
{ key: "healing", value: "e3f3"},
{ key: "health_and_safety", value: "e1d5"},
{ key: "hearing", value: "e023"},
{ key: "hearing_disabled", value: "f104"},
{ key: "heart_broken", value: "eac2"},
{ key: "heat_pump", value: "ec18"},
{ key: "height", value: "ea16"},
{ key: "help", value: "e887"},
{ key: "help_center", value: "f1c0"},
{ key: "help_outline", value: "e8fd"},
{ key: "hevc", value: "f021"},
{ key: "hexagon", value: "eb39"},
{ key: "hide_image", value: "f022"},
{ key: "hide_source", value: "f023"},
{ key: "high_quality", value: "e024"},
{ key: "highlight", value: "e25f"},
{ key: "highlight_alt", value: "ef52"},
{ key: "highlight_off", value: "e888"},
{ key: "highlight_remove", value: "e888"},
{ key: "hiking", value: "e50a"},
{ key: "history", value: "e889"},
{ key: "history_edu", value: "ea3e"},
{ key: "history_toggle_off", value: "f17d"},
{ key: "hive", value: "eaa6"},
{ key: "hls", value: "eb8a"},
{ key: "hls_off", value: "eb8c"},
{ key: "holiday_village", value: "e58a"},
{ key: "home", value: "e88a"},
{ key: "home_filled", value: "e9b2"},
{ key: "home_max", value: "f024"},
{ key: "home_mini", value: "f025"},
{ key: "home_repair_service", value: "f100"},
{ key: "home_work", value: "ea09"},
{ key: "horizontal_distribute", value: "e014"},
{ key: "horizontal_rule", value: "f108"},
{ key: "horizontal_split", value: "e947"},
{ key: "hot_tub", value: "eb46"},
{ key: "hotel", value: "e53a"},
{ key: "hotel_class", value: "e743"},
{ key: "hourglass_bottom", value: "ea5c"},
{ key: "hourglass_disabled", value: "ef53"},
{ key: "hourglass_empty", value: "e88b"},
{ key: "hourglass_full", value: "e88c"},
{ key: "hourglass_top", value: "ea5b"},
{ key: "house", value: "ea44"},
{ key: "house_siding", value: "f202"},
{ key: "houseboat", value: "e584"},
{ key: "how_to_reg", value: "e174"},
{ key: "how_to_vote", value: "e175"},
{ key: "html", value: "eb7e"},
{ key: "http", value: "e902"},
{ key: "https", value: "e88d"},
{ key: "hub", value: "e9f4"},
{ key: "hvac", value: "f10e"},
{ key: "ice_skating", value: "e50b"},
{ key: "icecream", value: "ea69"},
{ key: "image", value: "e3f4"},
{ key: "image_aspect_ratio", value: "e3f5"},
{ key: "image_not_supported", value: "f116"},
{ key: "image_search", value: "e43f"},
{ key: "imagesearch_roller", value: "e9b4"},
{ key: "import_contacts", value: "e0e0"},
{ key: "import_export", value: "e0c3"},
{ key: "important_devices", value: "e912"},
{ key: "inbox", value: "e156"},
{ key: "incomplete_circle", value: "e79b"},
{ key: "indeterminate_check_box", value: "e909"},
{ key: "info", value: "e88e"},
{ key: "info_outline", value: "e88f"},
{ key: "input", value: "e890"},
{ key: "insert_chart", value: "e24b"},
{ key: "insert_chart_outlined", value: "e26a"},
{ key: "insert_comment", value: "e24c"},
{ key: "insert_drive_file", value: "e24d"},
{ key: "insert_emoticon", value: "e24e"},
{ key: "insert_invitation", value: "e24f"},
{ key: "insert_link", value: "e250"},
{ key: "insert_page_break", value: "eaca"},
{ key: "insert_photo", value: "e251"},
{ key: "insights", value: "f092"},
{ key: "install_desktop", value: "eb71"},
{ key: "install_mobile", value: "eb72"},
{ key: "integration_instructions", value: "ef54"},
{ key: "interests", value: "e7c8"},
{ key: "interpreter_mode", value: "e83b"},
{ key: "inventory", value: "e179"},
{ key: "inventory_2", value: "e1a1"},
{ key: "invert_colors", value: "e891"},
{ key: "invert_colors_off", value: "e0c4"},
{ key: "invert_colors_on", value: "e891"},
{ key: "ios_share", value: "e6b8"},
{ key: "iron", value: "e583"},
{ key: "iso", value: "e3f6"},
{ key: "javascript", value: "eb7c"},
{ key: "join_full", value: "eaeb"},
{ key: "join_inner", value: "eaf4"},
{ key: "join_left", value: "eaf2"},
{ key: "join_right", value: "eaea"},
{ key: "kayaking", value: "e50c"},
{ key: "kebab_dining", value: "e842"},
{ key: "key", value: "e73c"},
{ key: "key_off", value: "eb84"},
{ key: "keyboard", value: "e312"},
{ key: "keyboard_alt", value: "f028"},
{ key: "keyboard_arrow_down", value: "e313"},
{ key: "keyboard_arrow_left", value: "e314"},
{ key: "keyboard_arrow_right", value: "e315"},
{ key: "keyboard_arrow_up", value: "e316"},
{ key: "keyboard_backspace", value: "e317"},
{ key: "keyboard_capslock", value: "e318"},
{ key: "keyboard_command", value: "eae0"},
{ key: "keyboard_command_key", value: "eae7"},
{ key: "keyboard_control", value: "e5d3"},
{ key: "keyboard_control_key", value: "eae6"},
{ key: "keyboard_double_arrow_down", value: "ead0"},
{ key: "keyboard_double_arrow_left", value: "eac3"},
{ key: "keyboard_double_arrow_right", value: "eac9"},
{ key: "keyboard_double_arrow_up", value: "eacf"},
{ key: "keyboard_hide", value: "e31a"},
{ key: "keyboard_option", value: "eadf"},
{ key: "keyboard_option_key", value: "eae8"},
{ key: "keyboard_return", value: "e31b"},
{ key: "keyboard_tab", value: "e31c"},
{ key: "keyboard_voice", value: "e31d"},
{ key: "king_bed", value: "ea45"},
{ key: "kitchen", value: "eb47"},
{ key: "kitesurfing", value: "e50d"},
{ key: "label", value: "e892"},
{ key: "label_important", value: "e937"},
{ key: "label_important_outline", value: "e948"},
{ key: "label_off", value: "e9b6"},
{ key: "label_outline", value: "e893"},
{ key: "lan", value: "eb2f"},
{ key: "landscape", value: "e3f7"},
{ key: "landslide", value: "ebd7"},
{ key: "language", value: "e894"},
{ key: "laptop", value: "e31e"},
{ key: "laptop_chromebook", value: "e31f"},
{ key: "laptop_mac", value: "e320"},
{ key: "laptop_windows", value: "e321"},
{ key: "last_page", value: "e5dd"},
{ key: "launch", value: "e895"},
{ key: "layers", value: "e53b"},
{ key: "layers_clear", value: "e53c"},
{ key: "leaderboard", value: "f20c"},
{ key: "leak_add", value: "e3f8"},
{ key: "leak_remove", value: "e3f9"},
{ key: "leave_bags_at_home", value: "f21b"},
{ key: "legend_toggle", value: "f11b"},
{ key: "lens", value: "e3fa"},
{ key: "lens_blur", value: "f029"},
{ key: "library_add", value: "e02e"},
{ key: "library_add_check", value: "e9b7"},
{ key: "library_books", value: "e02f"},
{ key: "library_music", value: "e030"},
{ key: "light", value: "f02a"},
{ key: "light_mode", value: "e518"},
{ key: "lightbulb", value: "e0f0"},
{ key: "lightbulb_circle", value: "ebfe"},
{ key: "lightbulb_outline", value: "e90f"},
{ key: "line_axis", value: "ea9a"},
{ key: "line_style", value: "e919"},
{ key: "line_weight", value: "e91a"},
{ key: "linear_scale", value: "e260"},
{ key: "link", value: "e157"},
{ key: "link_off", value: "e16f"},
{ key: "linked_camera", value: "e438"},
{ key: "liquor", value: "ea60"},
{ key: "list", value: "e896"},
{ key: "list_alt", value: "e0ee"},
{ key: "live_help", value: "e0c6"},
{ key: "live_tv", value: "e639"},
{ key: "living", value: "f02b"},
{ key: "local_activity", value: "e53f"},
{ key: "local_airport", value: "e53d"},
{ key: "local_atm", value: "e53e"},
{ key: "local_attraction", value: "e53f"},
{ key: "local_bar", value: "e540"},
{ key: "local_cafe", value: "e541"},
{ key: "local_car_wash", value: "e542"},
{ key: "local_convenience_store", value: "e543"},
{ key: "local_dining", value: "e556"},
{ key: "local_drink", value: "e544"},
{ key: "local_fire_department", value: "ef55"},
{ key: "local_florist", value: "e545"},
{ key: "local_gas_station", value: "e546"},
{ key: "local_grocery_store", value: "e547"},
{ key: "local_hospital", value: "e548"},
{ key: "local_hotel", value: "e549"},
{ key: "local_laundry_service", value: "e54a"},
{ key: "local_library", value: "e54b"},
{ key: "local_mall", value: "e54c"},
{ key: "local_movies", value: "e54d"},
{ key: "local_offer", value: "e54e"},
{ key: "local_parking", value: "e54f"},
{ key: "local_pharmacy", value: "e550"},
{ key: "local_phone", value: "e551"},
{ key: "local_pizza", value: "e552"},
{ key: "local_play", value: "e553"},
{ key: "local_police", value: "ef56"},
{ key: "local_post_office", value: "e554"},
{ key: "local_print_shop", value: "e555"},
{ key: "local_printshop", value: "e555"},
{ key: "local_restaurant", value: "e556"},
{ key: "local_see", value: "e557"},
{ key: "local_shipping", value: "e558"},
{ key: "local_taxi", value: "e559"},
{ key: "location_city", value: "e7f1"},
{ key: "location_disabled", value: "e1b6"},
{ key: "location_history", value: "e55a"},
{ key: "location_off", value: "e0c7"},
{ key: "location_on", value: "e0c8"},
{ key: "location_pin", value: "f1db"},
{ key: "location_searching", value: "e1b7"},
{ key: "lock", value: "e897"},
{ key: "lock_clock", value: "ef57"},
{ key: "lock_open", value: "e898"},
{ key: "lock_outline", value: "e899"},
{ key: "lock_person", value: "f8f3"},
{ key: "lock_reset", value: "eade"},
{ key: "login", value: "ea77"},
{ key: "logo_dev", value: "ead6"},
{ key: "logout", value: "e9ba"},
{ key: "looks", value: "e3fc"},
{ key: "looks_3", value: "e3fb"},
{ key: "looks_4", value: "e3fd"},
{ key: "looks_5", value: "e3fe"},
{ key: "looks_6", value: "e3ff"},
{ key: "looks_one", value: "e400"},
{ key: "looks_two", value: "e401"},
{ key: "loop", value: "e028"},
{ key: "loupe", value: "e402"},
{ key: "low_priority", value: "e16d"},
{ key: "loyalty", value: "e89a"},
{ key: "lte_mobiledata", value: "f02c"},
{ key: "lte_plus_mobiledata", value: "f02d"},
{ key: "luggage", value: "f235"},
{ key: "lunch_dining", value: "ea61"},
{ key: "lyrics", value: "ec0b"},
{ key: "macro_off", value: "f8d2"},
{ key: "mail", value: "e158"},
{ key: "mail_lock", value: "ec0a"},
{ key: "mail_outline", value: "e0e1"},
{ key: "male", value: "e58e"},
{ key: "man", value: "e4eb"},
{ key: "man_2", value: "f8e1"},
{ key: "man_3", value: "f8e2"},
{ key: "man_4", value: "f8e3"},
{ key: "manage_accounts", value: "f02e"},
{ key: "manage_history", value: "ebe7"},
{ key: "manage_search", value: "f02f"},
{ key: "map", value: "e55b"},
{ key: "maps_home_work", value: "f030"},
{ key: "maps_ugc", value: "ef58"},
{ key: "margin", value: "e9bb"},
{ key: "mark_as_unread", value: "e9bc"},
{ key: "mark_chat_read", value: "f18b"},
{ key: "mark_chat_unread", value: "f189"},
{ key: "mark_email_read", value: "f18c"},
{ key: "mark_email_unread", value: "f18a"},
{ key: "mark_unread_chat_alt", value: "eb9d"},
{ key: "markunread", value: "e159"},
{ key: "markunread_mailbox", value: "e89b"},
{ key: "masks", value: "f218"},
{ key: "maximize", value: "e930"},
{ key: "media_bluetooth_off", value: "f031"},
{ key: "media_bluetooth_on", value: "f032"},
{ key: "mediation", value: "efa7"},
{ key: "medical_information", value: "ebed"},
{ key: "medical_services", value: "f109"},
{ key: "medication", value: "f033"},
{ key: "medication_liquid", value: "ea87"},
{ key: "meeting_room", value: "eb4f"},
{ key: "memory", value: "e322"},
{ key: "menu", value: "e5d2"},
{ key: "menu_book", value: "ea19"},
{ key: "menu_open", value: "e9bd"},
{ key: "merge", value: "eb98"},
{ key: "merge_type", value: "e252"},
{ key: "message", value: "e0c9"},
{ key: "messenger", value: "e0ca"},
{ key: "messenger_outline", value: "e0cb"},
{ key: "mic", value: "e029"},
{ key: "mic_external_off", value: "ef59"},
{ key: "mic_external_on", value: "ef5a"},
{ key: "mic_none", value: "e02a"},
{ key: "mic_off", value: "e02b"},
{ key: "microwave", value: "f204"},
{ key: "military_tech", value: "ea3f"},
{ key: "minimize", value: "e931"},
{ key: "minor_crash", value: "ebf1"},
{ key: "miscellaneous_services", value: "f10c"},
{ key: "missed_video_call", value: "e073"},
{ key: "mms", value: "e618"},
{ key: "mobile_friendly", value: "e200"},
{ key: "mobile_off", value: "e201"},
{ key: "mobile_screen_share", value: "e0e7"},
{ key: "mobiledata_off", value: "f034"},
{ key: "mode", value: "f097"},
{ key: "mode_comment", value: "e253"},
{ key: "mode_edit", value: "e254"},
{ key: "mode_edit_outline", value: "f035"},
{ key: "mode_fan_off", value: "ec17"},
{ key: "mode_night", value: "f036"},
{ key: "mode_of_travel", value: "e7ce"},
{ key: "mode_standby", value: "f037"},
{ key: "model_training", value: "f0cf"},
{ key: "monetization_on", value: "e263"},
{ key: "money", value: "e57d"},
{ key: "money_off", value: "e25c"},
{ key: "money_off_csred", value: "f038"},
{ key: "monitor", value: "ef5b"},
{ key: "monitor_heart", value: "eaa2"},
{ key: "monitor_weight", value: "f039"},
{ key: "monochrome_photos", value: "e403"},
{ key: "mood", value: "e7f2"},
{ key: "mood_bad", value: "e7f3"},
{ key: "moped", value: "eb28"},
{ key: "more", value: "e619"},
{ key: "more_horiz", value: "e5d3"},
{ key: "more_time", value: "ea5d"},
{ key: "more_vert", value: "e5d4"},
{ key: "mosque", value: "eab2"},
{ key: "motion_photos_auto", value: "f03a"},
{ key: "motion_photos_off", value: "e9c0"},
{ key: "motion_photos_on", value: "e9c1"},
{ key: "motion_photos_pause", value: "f227"},
{ key: "motion_photos_paused", value: "e9c2"},
{ key: "motorcycle", value: "e91b"},
{ key: "mouse", value: "e323"},
{ key: "move_down", value: "eb61"},
{ key: "move_to_inbox", value: "e168"},
{ key: "move_up", value: "eb64"},
{ key: "movie", value: "e02c"},
{ key: "movie_creation", value: "e404"},
{ key: "movie_edit", value: "f840"},
{ key: "movie_filter", value: "e43a"},
{ key: "moving", value: "e501"},
{ key: "mp", value: "e9c3"},
{ key: "multiline_chart", value: "e6df"},
{ key: "multiple_stop", value: "f1b9"},
{ key: "multitrack_audio", value: "e1b8"},
{ key: "museum", value: "ea36"},
{ key: "music_note", value: "e405"},
{ key: "music_off", value: "e440"},
{ key: "music_video", value: "e063"},
{ key: "my_library_add", value: "e02e"},
{ key: "my_library_books", value: "e02f"},
{ key: "my_library_music", value: "e030"},
{ key: "my_location", value: "e55c"},
{ key: "nat", value: "ef5c"},
{ key: "nature", value: "e406"},
{ key: "nature_people", value: "e407"},
{ key: "navigate_before", value: "e408"},
{ key: "navigate_next", value: "e409"},
{ key: "navigation", value: "e55d"},
{ key: "near_me", value: "e569"},
{ key: "near_me_disabled", value: "f1ef"},
{ key: "nearby_error", value: "f03b"},
{ key: "nearby_off", value: "f03c"},
{ key: "nest_cam_wired_stand", value: "ec16"},
{ key: "network_cell", value: "e1b9"},
{ key: "network_check", value: "e640"},
{ key: "network_locked", value: "e61a"},
{ key: "network_ping", value: "ebca"},
{ key: "network_wifi", value: "e1ba"},
{ key: "network_wifi_1_bar", value: "ebe4"},
{ key: "network_wifi_2_bar", value: "ebd6"},
{ key: "network_wifi_3_bar", value: "ebe1"},
{ key: "new_label", value: "e609"},
{ key: "new_releases", value: "e031"},
{ key: "newspaper", value: "eb81"},
{ key: "next_plan", value: "ef5d"},
{ key: "next_week", value: "e16a"},
{ key: "nfc", value: "e1bb"},
{ key: "night_shelter", value: "f1f1"},
{ key: "nightlife", value: "ea62"},
{ key: "nightlight", value: "f03d"},
{ key: "nightlight_round", value: "ef5e"},
{ key: "nights_stay", value: "ea46"},
{ key: "no_accounts", value: "f03e"},
{ key: "no_adult_content", value: "f8fe"},
{ key: "no_backpack", value: "f237"},
{ key: "no_cell", value: "f1a4"},
{ key: "no_crash", value: "ebf0"},
{ key: "no_drinks", value: "f1a5"},
{ key: "no_encryption", value: "e641"},
{ key: "no_encryption_gmailerrorred", value: "f03f"},
{ key: "no_flash", value: "f1a6"},
{ key: "no_food", value: "f1a7"},
{ key: "no_luggage", value: "f23b"},
{ key: "no_meals", value: "f1d6"},
{ key: "no_meals_ouline", value: "f229"},
{ key: "no_meeting_room", value: "eb4e"},
{ key: "no_photography", value: "f1a8"},
{ key: "no_sim", value: "e0cc"},
{ key: "no_stroller", value: "f1af"},
{ key: "no_transfer", value: "f1d5"},
{ key: "noise_aware", value: "ebec"},
{ key: "noise_control_off", value: "ebf3"},
{ key: "nordic_walking", value: "e50e"},
{ key: "north", value: "f1e0"},
{ key: "north_east", value: "f1e1"},
{ key: "north_west", value: "f1e2"},
{ key: "not_accessible", value: "f0fe"},
{ key: "not_interested", value: "e033"},
{ key: "not_listed_location", value: "e575"},
{ key: "not_started", value: "f0d1"},
{ key: "note", value: "e06f"},
{ key: "note_add", value: "e89c"},
{ key: "note_alt", value: "f040"},
{ key: "notes", value: "e26c"},
{ key: "notification_add", value: "e399"},
{ key: "notification_important", value: "e004"},
{ key: "notifications", value: "e7f4"},
{ key: "notifications_active", value: "e7f7"},
{ key: "notifications_none", value: "e7f5"},
{ key: "notifications_off", value: "e7f6"},
{ key: "notifications_on", value: "e7f7"},
{ key: "notifications_paused", value: "e7f8"},
{ key: "now_wallpaper", value: "e1bc"},
{ key: "now_widgets", value: "e1bd"},
{ key: "numbers", value: "eac7"},
{ key: "offline_bolt", value: "e932"},
{ key: "offline_pin", value: "e90a"},
{ key: "offline_share", value: "e9c5"},
{ key: "oil_barrel", value: "ec15"},
{ key: "on_device_training", value: "ebfd"},
{ key: "ondemand_video", value: "e63a"},
{ key: "online_prediction", value: "f0eb"},
{ key: "opacity", value: "e91c"},
{ key: "open_in_browser", value: "e89d"},
{ key: "open_in_full", value: "f1ce"},
{ key: "open_in_new", value: "e89e"},
{ key: "open_in_new_off", value: "e4f6"},
{ key: "open_with", value: "e89f"},
{ key: "other_houses", value: "e58c"},
{ key: "outbond", value: "f228"},
{ key: "outbound", value: "e1ca"},
{ key: "outbox", value: "ef5f"},
{ key: "outdoor_grill", value: "ea47"},
{ key: "outgoing_mail", value: "f0d2"},
{ key: "outlet", value: "f1d4"},
{ key: "outlined_flag", value: "e16e"},
{ key: "output", value: "ebbe"},
{ key: "padding", value: "e9c8"},
{ key: "pages", value: "e7f9"},
{ key: "pageview", value: "e8a0"},
{ key: "paid", value: "f041"},
{ key: "palette", value: "e40a"},
{ key: "pallet", value: "f86a"},
{ key: "pan_tool", value: "e925"},
{ key: "pan_tool_alt", value: "ebb9"},
{ key: "panorama", value: "e40b"},
{ key: "panorama_fish_eye", value: "e40c"},
{ key: "panorama_fisheye", value: "e40c"},
{ key: "panorama_horizontal", value: "e40d"},
{ key: "panorama_horizontal_select", value: "ef60"},
{ key: "panorama_photosphere", value: "e9c9"},
{ key: "panorama_photosphere_select", value: "e9ca"},
{ key: "panorama_vertical", value: "e40e"},
{ key: "panorama_vertical_select", value: "ef61"},
{ key: "panorama_wide_angle", value: "e40f"},
{ key: "panorama_wide_angle_select", value: "ef62"},
{ key: "paragliding", value: "e50f"},
{ key: "park", value: "ea63"},
{ key: "party_mode", value: "e7fa"},
{ key: "password", value: "f042"},
{ key: "pattern", value: "f043"},
{ key: "pause", value: "e034"},
{ key: "pause_circle", value: "e1a2"},
{ key: "pause_circle_filled", value: "e035"},
{ key: "pause_circle_outline", value: "e036"},
{ key: "pause_presentation", value: "e0ea"},
{ key: "payment", value: "e8a1"},
{ key: "payments", value: "ef63"},
{ key: "paypal", value: "ea8d"},
{ key: "pedal_bike", value: "eb29"},
{ key: "pending", value: "ef64"},
{ key: "pending_actions", value: "f1bb"},
{ key: "pentagon", value: "eb50"},
{ key: "people", value: "e7fb"},
{ key: "people_alt", value: "ea21"},
{ key: "people_outline", value: "e7fc"},
{ key: "percent", value: "eb58"},
{ key: "perm_camera_mic", value: "e8a2"},
{ key: "perm_contact_cal", value: "e8a3"},
{ key: "perm_contact_calendar", value: "e8a3"},
{ key: "perm_data_setting", value: "e8a4"},
{ key: "perm_device_info", value: "e8a5"},
{ key: "perm_device_information", value: "e8a5"},
{ key: "perm_identity", value: "e8a6"},
{ key: "perm_media", value: "e8a7"},
{ key: "perm_phone_msg", value: "e8a8"},
{ key: "perm_scan_wifi", value: "e8a9"},
{ key: "person", value: "e7fd"},
{ key: "person_2", value: "f8e4"},
{ key: "person_3", value: "f8e5"},
{ key: "person_4", value: "f8e6"},
{ key: "person_add", value: "e7fe"},
{ key: "person_add_alt", value: "ea4d"},
{ key: "person_add_alt_1", value: "ef65"},
{ key: "person_add_disabled", value: "e9cb"},
{ key: "person_off", value: "e510"},
{ key: "person_outline", value: "e7ff"},
{ key: "person_pin", value: "e55a"},
{ key: "person_pin_circle", value: "e56a"},
{ key: "person_remove", value: "ef66"},
{ key: "person_remove_alt_1", value: "ef67"},
{ key: "person_search", value: "f106"},
{ key: "personal_injury", value: "e6da"},
{ key: "personal_video", value: "e63b"},
{ key: "pest_control", value: "f0fa"},
{ key: "pest_control_rodent", value: "f0fd"},
{ key: "pets", value: "e91d"},
{ key: "phishing", value: "ead7"},
{ key: "phone", value: "e0cd"},
{ key: "phone_android", value: "e324"},
{ key: "phone_bluetooth_speaker", value: "e61b"},
{ key: "phone_callback", value: "e649"},
{ key: "phone_disabled", value: "e9cc"},
{ key: "phone_enabled", value: "e9cd"},
{ key: "phone_forwarded", value: "e61c"},
{ key: "phone_in_talk", value: "e61d"},
{ key: "phone_iphone", value: "e325"},
{ key: "phone_locked", value: "e61e"},
{ key: "phone_missed", value: "e61f"},
{ key: "phone_paused", value: "e620"},
{ key: "phonelink", value: "e326"},
{ key: "phonelink_erase", value: "e0db"},
{ key: "phonelink_lock", value: "e0dc"},
{ key: "phonelink_off", value: "e327"},
{ key: "phonelink_ring", value: "e0dd"},
{ key: "phonelink_setup", value: "e0de"},
{ key: "photo", value: "e410"},
{ key: "photo_album", value: "e411"},
{ key: "photo_camera", value: "e412"},
{ key: "photo_camera_back", value: "ef68"},
{ key: "photo_camera_front", value: "ef69"},
{ key: "photo_filter", value: "e43b"},
{ key: "photo_library", value: "e413"},
{ key: "photo_size_select_actual", value: "e432"},
{ key: "photo_size_select_large", value: "e433"},
{ key: "photo_size_select_small", value: "e434"},
{ key: "php", value: "eb8f"},
{ key: "piano", value: "e521"},
{ key: "piano_off", value: "e520"},
{ key: "picture_as_pdf", value: "e415"},
{ key: "picture_in_picture", value: "e8aa"},
{ key: "picture_in_picture_alt", value: "e911"},
{ key: "pie_chart", value: "e6c4"},
{ key: "pie_chart_outline", value: "f044"},
{ key: "pie_chart_outlined", value: "e6c5"},
{ key: "pin", value: "f045"},
{ key: "pin_drop", value: "e55e"},
{ key: "pin_end", value: "e767"},
{ key: "pin_invoke", value: "e763"},
{ key: "pinch", value: "eb38"},
{ key: "pivot_table_chart", value: "e9ce"},
{ key: "pix", value: "eaa3"},
{ key: "place", value: "e55f"},
{ key: "plagiarism", value: "ea5a"},
{ key: "play_arrow", value: "e037"},
{ key: "play_circle", value: "e1c4"},
{ key: "play_circle_fill", value: "e038"},
{ key: "play_circle_filled", value: "e038"},
{ key: "play_circle_outline", value: "e039"},
{ key: "play_disabled", value: "ef6a"},
{ key: "play_for_work", value: "e906"},
{ key: "play_lesson", value: "f047"},
{ key: "playlist_add", value: "e03b"},
{ key: "playlist_add_check", value: "e065"},
{ key: "playlist_add_check_circle", value: "e7e6"},
{ key: "playlist_add_circle", value: "e7e5"},
{ key: "playlist_play", value: "e05f"},
{ key: "playlist_remove", value: "eb80"},
{ key: "plumbing", value: "f107"},
{ key: "plus_one", value: "e800"},
{ key: "podcasts", value: "f048"},
{ key: "point_of_sale", value: "f17e"},
{ key: "policy", value: "ea17"},
{ key: "poll", value: "e801"},
{ key: "polyline", value: "ebbb"},
{ key: "polymer", value: "e8ab"},
{ key: "pool", value: "eb48"},
{ key: "portable_wifi_off", value: "e0ce"},
{ key: "portrait", value: "e416"},
{ key: "post_add", value: "ea20"},
{ key: "power", value: "e63c"},
{ key: "power_input", value: "e336"},
{ key: "power_off", value: "e646"},
{ key: "power_settings_new", value: "e8ac"},
{ key: "precision_manufacturing", value: "f049"},
{ key: "pregnant_woman", value: "e91e"},
{ key: "present_to_all", value: "e0df"},
{ key: "preview", value: "f1c5"},
{ key: "price_change", value: "f04a"},
{ key: "price_check", value: "f04b"},
{ key: "print", value: "e8ad"},
{ key: "print_disabled", value: "e9cf"},
{ key: "priority_high", value: "e645"},
{ key: "privacy_tip", value: "f0dc"},
{ key: "private_connectivity", value: "e744"},
{ key: "production_quantity_limits", value: "e1d1"},
{ key: "propane", value: "ec14"},
{ key: "propane_tank", value: "ec13"},
{ key: "psychology", value: "ea4a"},
{ key: "psychology_alt", value: "f8ea"},
{ key: "public", value: "e80b"},
{ key: "public_off", value: "f1ca"},
{ key: "publish", value: "e255"},
{ key: "published_with_changes", value: "f232"},
{ key: "punch_clock", value: "eaa8"},
{ key: "push_pin", value: "f10d"},
{ key: "qr_code", value: "ef6b"},
{ key: "qr_code_2", value: "e00a"},
{ key: "qr_code_scanner", value: "f206"},
{ key: "query_builder", value: "e8ae"},
{ key: "query_stats", value: "e4fc"},
{ key: "question_answer", value: "e8af"},
{ key: "question_mark", value: "eb8b"},
{ key: "queue", value: "e03c"},
{ key: "queue_music", value: "e03d"},
{ key: "queue_play_next", value: "e066"},
{ key: "quick_contacts_dialer", value: "e0cf"},
{ key: "quick_contacts_mail", value: "e0d0"},
{ key: "quickreply", value: "ef6c"},
{ key: "quiz", value: "f04c"},
{ key: "quora", value: "ea98"},
{ key: "r_mobiledata", value: "f04d"},
{ key: "radar", value: "f04e"},
{ key: "radio", value: "e03e"},
{ key: "radio_button_checked", value: "e837"},
{ key: "radio_button_off", value: "e836"},
{ key: "radio_button_on", value: "e837"},
{ key: "radio_button_unchecked", value: "e836"},
{ key: "railway_alert", value: "e9d1"},
{ key: "ramen_dining", value: "ea64"},
{ key: "ramp_left", value: "eb9c"},
{ key: "ramp_right", value: "eb96"},
{ key: "rate_review", value: "e560"},
{ key: "raw_off", value: "f04f"},
{ key: "raw_on", value: "f050"},
{ key: "read_more", value: "ef6d"},
{ key: "real_estate_agent", value: "e73a"},
{ key: "rebase_edit", value: "f846"},
{ key: "receipt", value: "e8b0"},
{ key: "receipt_long", value: "ef6e"},
{ key: "recent_actors", value: "e03f"},
{ key: "recommend", value: "e9d2"},
{ key: "record_voice_over", value: "e91f"},
{ key: "rectangle", value: "eb54"},
{ key: "recycling", value: "e760"},
{ key: "reddit", value: "eaa0"},
{ key: "redeem", value: "e8b1"},
{ key: "redo", value: "e15a"},
{ key: "reduce_capacity", value: "f21c"},
{ key: "refresh", value: "e5d5"},
{ key: "remember_me", value: "f051"},
{ key: "remove", value: "e15b"},
{ key: "remove_circle", value: "e15c"},
{ key: "remove_circle_outline", value: "e15d"},
{ key: "remove_done", value: "e9d3"},
{ key: "remove_from_queue", value: "e067"},
{ key: "remove_moderator", value: "e9d4"},
{ key: "remove_red_eye", value: "e417"},
{ key: "remove_road", value: "ebfc"},
{ key: "remove_shopping_cart", value: "e928"},
{ key: "reorder", value: "e8fe"},
{ key: "repartition", value: "f8e8"},
{ key: "repeat", value: "e040"},
{ key: "repeat_on", value: "e9d6"},
{ key: "repeat_one", value: "e041"},
{ key: "repeat_one_on", value: "e9d7"},
{ key: "replay", value: "e042"},
{ key: "replay_10", value: "e059"},
{ key: "replay_30", value: "e05a"},
{ key: "replay_5", value: "e05b"},
{ key: "replay_circle_filled", value: "e9d8"},
{ key: "reply", value: "e15e"},
{ key: "reply_all", value: "e15f"},
{ key: "report", value: "e160"},
{ key: "report_gmailerrorred", value: "f052"},
{ key: "report_off", value: "e170"},
{ key: "report_problem", value: "e8b2"},
{ key: "request_page", value: "f22c"},
{ key: "request_quote", value: "f1b6"},
{ key: "reset_tv", value: "e9d9"},
{ key: "restart_alt", value: "f053"},
{ key: "restaurant", value: "e56c"},
{ key: "restaurant_menu", value: "e561"},
{ key: "restore", value: "e8b3"},
{ key: "restore_from_trash", value: "e938"},
{ key: "restore_page", value: "e929"},
{ key: "reviews", value: "f054"},
{ key: "rice_bowl", value: "f1f5"},
{ key: "ring_volume", value: "e0d1"},
{ key: "rocket", value: "eba5"},
{ key: "rocket_launch", value: "eb9b"},
{ key: "roller_shades", value: "ec12"},
{ key: "roller_shades_closed", value: "ec11"},
{ key: "roller_skating", value: "ebcd"},
{ key: "roofing", value: "f201"},
{ key: "room", value: "e8b4"},
{ key: "room_preferences", value: "f1b8"},
{ key: "room_service", value: "eb49"},
{ key: "rotate_90_degrees_ccw", value: "e418"},
{ key: "rotate_90_degrees_cw", value: "eaab"},
{ key: "rotate_left", value: "e419"},
{ key: "rotate_right", value: "e41a"},
{ key: "roundabout_left", value: "eb99"},
{ key: "roundabout_right", value: "eba3"},
{ key: "rounded_corner", value: "e920"},
{ key: "route", value: "eacd"},
{ key: "router", value: "e328"},
{ key: "rowing", value: "e921"},
{ key: "rss_feed", value: "e0e5"},
{ key: "rsvp", value: "f055"},
{ key: "rtt", value: "e9ad"},
{ key: "rule", value: "f1c2"},
{ key: "rule_folder", value: "f1c9"},
{ key: "run_circle", value: "ef6f"},
{ key: "running_with_errors", value: "e51d"},
{ key: "rv_hookup", value: "e642"},
{ key: "safety_check", value: "ebef"},
{ key: "safety_divider", value: "e1cc"},
{ key: "sailing", value: "e502"},
{ key: "sanitizer", value: "f21d"},
{ key: "satellite", value: "e562"},
{ key: "satellite_alt", value: "eb3a"},
{ key: "save", value: "e161"},
{ key: "save_alt", value: "e171"},
{ key: "save_as", value: "eb60"},
{ key: "saved_search", value: "ea11"},
{ key: "savings", value: "e2eb"},
{ key: "scale", value: "eb5f"},
{ key: "scanner", value: "e329"},
{ key: "scatter_plot", value: "e268"},
{ key: "schedule", value: "e8b5"},
{ key: "schedule_send", value: "ea0a"},
{ key: "schema", value: "e4fd"},
{ key: "school", value: "e80c"},
{ key: "science", value: "ea4b"},
{ key: "score", value: "e269"},
{ key: "scoreboard", value: "ebd0"},
{ key: "screen_lock_landscape", value: "e1be"},
{ key: "screen_lock_portrait", value: "e1bf"},
{ key: "screen_lock_rotation", value: "e1c0"},
{ key: "screen_rotation", value: "e1c1"},
{ key: "screen_rotation_alt", value: "ebee"},
{ key: "screen_search_desktop", value: "ef70"},
{ key: "screen_share", value: "e0e2"},
{ key: "screenshot", value: "f056"},
{ key: "screenshot_monitor", value: "ec08"},
{ key: "scuba_diving", value: "ebce"},
{ key: "sd", value: "e9dd"},
{ key: "sd_card", value: "e623"},
{ key: "sd_card_alert", value: "f057"},
{ key: "sd_storage", value: "e1c2"},
{ key: "search", value: "e8b6"},
{ key: "search_off", value: "ea76"},
{ key: "security", value: "e32a"},
{ key: "security_update", value: "f058"},
{ key: "security_update_good", value: "f059"},
{ key: "security_update_warning", value: "f05a"},
{ key: "segment", value: "e94b"},
{ key: "select_all", value: "e162"},
{ key: "self_improvement", value: "ea78"},
{ key: "sell", value: "f05b"},
{ key: "send", value: "e163"},
{ key: "send_and_archive", value: "ea0c"},
{ key: "send_time_extension", value: "eadb"},
{ key: "send_to_mobile", value: "f05c"},
{ key: "sensor_door", value: "f1b5"},
{ key: "sensor_occupied", value: "ec10"},
{ key: "sensor_window", value: "f1b4"},
{ key: "sensors", value: "e51e"},
{ key: "sensors_off", value: "e51f"},
{ key: "sentiment_dissatisfied", value: "e811"},
{ key: "sentiment_neutral", value: "e812"},
{ key: "sentiment_satisfied", value: "e813"},
{ key: "sentiment_satisfied_alt", value: "e0ed"},
{ key: "sentiment_very_dissatisfied", value: "e814"},
{ key: "sentiment_very_satisfied", value: "e815"},
{ key: "set_meal", value: "f1ea"},
{ key: "settings", value: "e8b8"},
{ key: "settings_accessibility", value: "f05d"},
{ key: "settings_applications", value: "e8b9"},
{ key: "settings_backup_restore", value: "e8ba"},
{ key: "settings_bluetooth", value: "e8bb"},
{ key: "settings_brightness", value: "e8bd"},
{ key: "settings_cell", value: "e8bc"},
{ key: "settings_display", value: "e8bd"},
{ key: "settings_ethernet", value: "e8be"},
{ key: "settings_input_antenna", value: "e8bf"},
{ key: "settings_input_component", value: "e8c0"},
{ key: "settings_input_composite", value: "e8c1"},
{ key: "settings_input_hdmi", value: "e8c2"},
{ key: "settings_input_svideo", value: "e8c3"},
{ key: "settings_overscan", value: "e8c4"},
{ key: "settings_phone", value: "e8c5"},
{ key: "settings_power", value: "e8c6"},
{ key: "settings_remote", value: "e8c7"},
{ key: "settings_suggest", value: "f05e"},
{ key: "settings_system_daydream", value: "e1c3"},
{ key: "settings_voice", value: "e8c8"},
{ key: "severe_cold", value: "ebd3"},
{ key: "shape_line", value: "f8d3"},
{ key: "share", value: "e80d"},
{ key: "share_arrival_time", value: "e524"},
{ key: "share_location", value: "f05f"},
{ key: "shelves", value: "f86e"},
{ key: "shield", value: "e9e0"},
{ key: "shield_moon", value: "eaa9"},
{ key: "shop", value: "e8c9"},
{ key: "shop_2", value: "e19e"},
{ key: "shop_two", value: "e8ca"},
{ key: "shopify", value: "ea9d"},
{ key: "shopping_bag", value: "f1cc"},
{ key: "shopping_basket", value: "e8cb"},
{ key: "shopping_cart", value: "e8cc"},
{ key: "shopping_cart_checkout", value: "eb88"},
{ key: "short_text", value: "e261"},
{ key: "shortcut", value: "f060"},
{ key: "show_chart", value: "e6e1"},
{ key: "shower", value: "f061"},
{ key: "shuffle", value: "e043"},
{ key: "shuffle_on", value: "e9e1"},
{ key: "shutter_speed", value: "e43d"},
{ key: "sick", value: "f220"},
{ key: "sign_language", value: "ebe5"},
{ key: "signal_cellular_0_bar", value: "f0a8"},
{ key: "signal_cellular_4_bar", value: "e1c8"},
{ key: "signal_cellular_alt", value: "e202"},
{ key: "signal_cellular_alt_1_bar", value: "ebdf"},
{ key: "signal_cellular_alt_2_bar", value: "ebe3"},
{ key: "signal_cellular_connected_no_internet_0_bar", value: "f0ac"},
{ key: "signal_cellular_connected_no_internet_4_bar", value: "e1cd"},
{ key: "signal_cellular_no_sim", value: "e1ce"},
{ key: "signal_cellular_nodata", value: "f062"},
{ key: "signal_cellular_null", value: "e1cf"},
{ key: "signal_cellular_off", value: "e1d0"},
{ key: "signal_wifi_0_bar", value: "f0b0"},
{ key: "signal_wifi_4_bar", value: "e1d8"},
{ key: "signal_wifi_4_bar_lock", value: "e1d9"},
{ key: "signal_wifi_bad", value: "f063"},
{ key: "signal_wifi_connected_no_internet_4", value: "f064"},
{ key: "signal_wifi_off", value: "e1da"},
{ key: "signal_wifi_statusbar_4_bar", value: "f065"},
{ key: "signal_wifi_statusbar_connected_no_internet_4", value: "f066"},
{ key: "signal_wifi_statusbar_null", value: "f067"},
{ key: "signpost", value: "eb91"},
{ key: "sim_card", value: "e32b"},
{ key: "sim_card_alert", value: "e624"},
{ key: "sim_card_download", value: "f068"},
{ key: "single_bed", value: "ea48"},
{ key: "sip", value: "f069"},
{ key: "skateboarding", value: "e511"},
{ key: "skip_next", value: "e044"},
{ key: "skip_previous", value: "e045"},
{ key: "sledding", value: "e512"},
{ key: "slideshow", value: "e41b"},
{ key: "slow_motion_video", value: "e068"},
{ key: "smart_button", value: "f1c1"},
{ key: "smart_display", value: "f06a"},
{ key: "smart_screen", value: "f06b"},
{ key: "smart_toy", value: "f06c"},
{ key: "smartphone", value: "e32c"},
{ key: "smoke_free", value: "eb4a"},
{ key: "smoking_rooms", value: "eb4b"},
{ key: "sms", value: "e625"},
{ key: "sms_failed", value: "e626"},
{ key: "snapchat", value: "ea6e"},
{ key: "snippet_folder", value: "f1c7"},
{ key: "snooze", value: "e046"},
{ key: "snowboarding", value: "e513"},
{ key: "snowing", value: "e80f"},
{ key: "snowmobile", value: "e503"},
{ key: "snowshoeing", value: "e514"},
{ key: "soap", value: "f1b2"},
{ key: "social_distance", value: "e1cb"},
{ key: "solar_power", value: "ec0f"},
{ key: "sort", value: "e164"},
{ key: "sort_by_alpha", value: "e053"},
{ key: "sos", value: "ebf7"},
{ key: "soup_kitchen", value: "e7d3"},
{ key: "source", value: "f1c4"},
{ key: "south", value: "f1e3"},
{ key: "south_america", value: "e7e4"},
{ key: "south_east", value: "f1e4"},
{ key: "south_west", value: "f1e5"},
{ key: "spa", value: "eb4c"},
{ key: "space_bar", value: "e256"},
{ key: "space_dashboard", value: "e66b"},
{ key: "spatial_audio", value: "ebeb"},
{ key: "spatial_audio_off", value: "ebe8"},
{ key: "spatial_tracking", value: "ebea"},
{ key: "speaker", value: "e32d"},
{ key: "speaker_group", value: "e32e"},
{ key: "speaker_notes", value: "e8cd"},
{ key: "speaker_notes_off", value: "e92a"},
{ key: "speaker_phone", value: "e0d2"},
{ key: "speed", value: "e9e4"},
{ key: "spellcheck", value: "e8ce"},
{ key: "splitscreen", value: "f06d"},
{ key: "spoke", value: "e9a7"},
{ key: "sports", value: "ea30"},
{ key: "sports_bar", value: "f1f3"},
{ key: "sports_baseball", value: "ea51"},
{ key: "sports_basketball", value: "ea26"},
{ key: "sports_cricket", value: "ea27"},
{ key: "sports_esports", value: "ea28"},
{ key: "sports_football", value: "ea29"},
{ key: "sports_golf", value: "ea2a"},
{ key: "sports_gymnastics", value: "ebc4"},
{ key: "sports_handball", value: "ea33"},
{ key: "sports_hockey", value: "ea2b"},
{ key: "sports_kabaddi", value: "ea34"},
{ key: "sports_martial_arts", value: "eae9"},
{ key: "sports_mma", value: "ea2c"},
{ key: "sports_motorsports", value: "ea2d"},
{ key: "sports_rugby", value: "ea2e"},
{ key: "sports_score", value: "f06e"},
{ key: "sports_soccer", value: "ea2f"},
{ key: "sports_tennis", value: "ea32"},
{ key: "sports_volleyball", value: "ea31"},
{ key: "square", value: "eb36"},
{ key: "square_foot", value: "ea49"},
{ key: "ssid_chart", value: "eb66"},
{ key: "stacked_bar_chart", value: "e9e6"},
{ key: "stacked_line_chart", value: "f22b"},
{ key: "stadium", value: "eb90"},
{ key: "stairs", value: "f1a9"},
{ key: "star", value: "e838"},
{ key: "star_border", value: "e83a"},
{ key: "star_border_purple500", value: "f099"},
{ key: "star_half", value: "e839"},
{ key: "star_outline", value: "f06f"},
{ key: "star_purple500", value: "f09a"},
{ key: "star_rate", value: "f0ec"},
{ key: "stars", value: "e8d0"},
{ key: "start", value: "e089"},
{ key: "stay_current_landscape", value: "e0d3"},
{ key: "stay_current_portrait", value: "e0d4"},
{ key: "stay_primary_landscape", value: "e0d5"},
{ key: "stay_primary_portrait", value: "e0d6"},
{ key: "sticky_note_2", value: "f1fc"},
{ key: "stop", value: "e047"},
{ key: "stop_circle", value: "ef71"},
{ key: "stop_screen_share", value: "e0e3"},
{ key: "storage", value: "e1db"},
{ key: "store", value: "e8d1"},
{ key: "store_mall_directory", value: "e563"},
{ key: "storefront", value: "ea12"},
{ key: "storm", value: "f070"},
{ key: "straight", value: "eb95"},
{ key: "straighten", value: "e41c"},
{ key: "stream", value: "e9e9"},
{ key: "streetview", value: "e56e"},
{ key: "strikethrough_s", value: "e257"},
{ key: "stroller", value: "f1ae"},
{ key: "style", value: "e41d"},
{ key: "subdirectory_arrow_left", value: "e5d9"},
{ key: "subdirectory_arrow_right", value: "e5da"},
{ key: "subject", value: "e8d2"},
{ key: "subscript", value: "f111"},
{ key: "subscriptions", value: "e064"},
{ key: "subtitles", value: "e048"},
{ key: "subtitles_off", value: "ef72"},
{ key: "subway", value: "e56f"},
{ key: "summarize", value: "f071"},
{ key: "sunny", value: "e81a"},
{ key: "sunny_snowing", value: "e819"},
{ key: "superscript", value: "f112"},
{ key: "supervised_user_circle", value: "e939"},
{ key: "supervisor_account", value: "e8d3"},
{ key: "support", value: "ef73"},
{ key: "support_agent", value: "f0e2"},
{ key: "surfing", value: "e515"},
{ key: "surround_sound", value: "e049"},
{ key: "swap_calls", value: "e0d7"},
{ key: "swap_horiz", value: "e8d4"},
{ key: "swap_horizontal_circle", value: "e933"},
{ key: "swap_vert", value: "e8d5"},
{ key: "swap_vert_circle", value: "e8d6"},
{ key: "swap_vertical_circle", value: "e8d6"},
{ key: "swipe", value: "e9ec"},
{ key: "swipe_down", value: "eb53"},
{ key: "swipe_down_alt", value: "eb30"},
{ key: "swipe_left", value: "eb59"},
{ key: "swipe_left_alt", value: "eb33"},
{ key: "swipe_right", value: "eb52"},
{ key: "swipe_right_alt", value: "eb56"},
{ key: "swipe_up", value: "eb2e"},
{ key: "swipe_up_alt", value: "eb35"},
{ key: "swipe_vertical", value: "eb51"},
{ key: "switch_access_shortcut", value: "e7e1"},
{ key: "switch_access_shortcut_add", value: "e7e2"},
{ key: "switch_account", value: "e9ed"},
{ key: "switch_camera", value: "e41e"},
{ key: "switch_left", value: "f1d1"},
{ key: "switch_right", value: "f1d2"},
{ key: "switch_video", value: "e41f"},
{ key: "synagogue", value: "eab0"},
{ key: "sync", value: "e627"},
{ key: "sync_alt", value: "ea18"},
{ key: "sync_disabled", value: "e628"},
{ key: "sync_lock", value: "eaee"},
{ key: "sync_problem", value: "e629"},
{ key: "system_security_update", value: "f072"},
{ key: "system_security_update_good", value: "f073"},
{ key: "system_security_update_warning", value: "f074"},
{ key: "system_update", value: "e62a"},
{ key: "system_update_alt", value: "e8d7"},
{ key: "system_update_tv", value: "e8d7"},
{ key: "tab", value: "e8d8"},
{ key: "tab_unselected", value: "e8d9"},
{ key: "table_bar", value: "ead2"},
{ key: "table_chart", value: "e265"},
{ key: "table_restaurant", value: "eac6"},
{ key: "table_rows", value: "f101"},
{ key: "table_view", value: "f1be"},
{ key: "tablet", value: "e32f"},
{ key: "tablet_android", value: "e330"},
{ key: "tablet_mac", value: "e331"},
{ key: "tag", value: "e9ef"},
{ key: "tag_faces", value: "e420"},
{ key: "takeout_dining", value: "ea74"},
{ key: "tap_and_play", value: "e62b"},
{ key: "tapas", value: "f1e9"},
{ key: "task", value: "f075"},
{ key: "task_alt", value: "e2e6"},
{ key: "taxi_alert", value: "ef74"},
{ key: "telegram", value: "ea6b"},
{ key: "temple_buddhist", value: "eab3"},
{ key: "temple_hindu", value: "eaaf"},
{ key: "terminal", value: "eb8e"},
{ key: "terrain", value: "e564"},
{ key: "text_decrease", value: "eadd"},
{ key: "text_fields", value: "e262"},
{ key: "text_format", value: "e165"},
{ key: "text_increase", value: "eae2"},
{ key: "text_rotate_up", value: "e93a"},
{ key: "text_rotate_vertical", value: "e93b"},
{ key: "text_rotation_angledown", value: "e93c"},
{ key: "text_rotation_angleup", value: "e93d"},
{ key: "text_rotation_down", value: "e93e"},
{ key: "text_rotation_none", value: "e93f"},
{ key: "text_snippet", value: "f1c6"},
{ key: "textsms", value: "e0d8"},
{ key: "texture", value: "e421"},
{ key: "theater_comedy", value: "ea66"},
{ key: "theaters", value: "e8da"},
{ key: "thermostat", value: "f076"},
{ key: "thermostat_auto", value: "f077"},
{ key: "thumb_down", value: "e8db"},
{ key: "thumb_down_alt", value: "e816"},
{ key: "thumb_down_off_alt", value: "e9f2"},
{ key: "thumb_up", value: "e8dc"},
{ key: "thumb_up_alt", value: "e817"},
{ key: "thumb_up_off_alt", value: "e9f3"},
{ key: "thumbs_up_down", value: "e8dd"},
{ key: "thunderstorm", value: "ebdb"},
{ key: "tiktok", value: "ea7e"},
{ key: "time_to_leave", value: "e62c"},
{ key: "timelapse", value: "e422"},
{ key: "timeline", value: "e922"},
{ key: "timer", value: "e425"},
{ key: "timer_10", value: "e423"},
{ key: "timer_10_select", value: "f07a"},
{ key: "timer_3", value: "e424"},
{ key: "timer_3_select", value: "f07b"},
{ key: "timer_off", value: "e426"},
{ key: "tips_and_updates", value: "e79a"},
{ key: "tire_repair", value: "ebc8"},
{ key: "title", value: "e264"},
{ key: "toc", value: "e8de"},
{ key: "today", value: "e8df"},
{ key: "toggle_off", value: "e9f5"},
{ key: "toggle_on", value: "e9f6"},
{ key: "token", value: "ea25"},
{ key: "toll", value: "e8e0"},
{ key: "tonality", value: "e427"},
{ key: "topic", value: "f1c8"},
{ key: "tornado", value: "e199"},
{ key: "touch_app", value: "e913"},
{ key: "tour", value: "ef75"},
{ key: "toys", value: "e332"},
{ key: "track_changes", value: "e8e1"},
{ key: "traffic", value: "e565"},
{ key: "train", value: "e570"},
{ key: "tram", value: "e571"},
{ key: "transcribe", value: "f8ec"},
{ key: "transfer_within_a_station", value: "e572"},
{ key: "transform", value: "e428"},
{ key: "transgender", value: "e58d"},
{ key: "transit_enterexit", value: "e579"},
{ key: "translate", value: "e8e2"},
{ key: "travel_explore", value: "e2db"},
{ key: "trending_down", value: "e8e3"},
{ key: "trending_flat", value: "e8e4"},
{ key: "trending_neutral", value: "e8e4"},
{ key: "trending_up", value: "e8e5"},
{ key: "trip_origin", value: "e57b"},
{ key: "trolley", value: "f86b"},
{ key: "troubleshoot", value: "e1d2"},
{ key: "try", value: "f07c"},
{ key: "tsunami", value: "ebd8"},
{ key: "tty", value: "f1aa"},
{ key: "tune", value: "e429"},
{ key: "tungsten", value: "f07d"},
{ key: "turn_left", value: "eba6"},
{ key: "turn_right", value: "ebab"},
{ key: "turn_sharp_left", value: "eba7"},
{ key: "turn_sharp_right", value: "ebaa"},
{ key: "turn_slight_left", value: "eba4"},
{ key: "turn_slight_right", value: "eb9a"},
{ key: "turned_in", value: "e8e6"},
{ key: "turned_in_not", value: "e8e7"},
{ key: "tv", value: "e333"},
{ key: "tv_off", value: "e647"},
{ key: "two_wheeler", value: "e9f9"},
{ key: "type_specimen", value: "f8f0"},
{ key: "u_turn_left", value: "eba1"},
{ key: "u_turn_right", value: "eba2"},
{ key: "umbrella", value: "f1ad"},
{ key: "unarchive", value: "e169"},
{ key: "undo", value: "e166"},
{ key: "unfold_less", value: "e5d6"},
{ key: "unfold_less_double", value: "f8cf"},
{ key: "unfold_more", value: "e5d7"},
{ key: "unfold_more_double", value: "f8d0"},
{ key: "unpublished", value: "f236"},
{ key: "unsubscribe", value: "e0eb"},
{ key: "upcoming", value: "f07e"},
{ key: "update", value: "e923"},
{ key: "update_disabled", value: "e075"},
{ key: "upgrade", value: "f0fb"},
{ key: "upload", value: "f09b"},
{ key: "upload_file", value: "e9fc"},
{ key: "usb", value: "e1e0"},
{ key: "usb_off", value: "e4fa"},
{ key: "vaccines", value: "e138"},
{ key: "vape_free", value: "ebc6"},
{ key: "vaping_rooms", value: "ebcf"},
{ key: "verified", value: "ef76"},
{ key: "verified_user", value: "e8e8"},
{ key: "vertical_align_bottom", value: "e258"},
{ key: "vertical_align_center", value: "e259"},
{ key: "vertical_align_top", value: "e25a"},
{ key: "vertical_distribute", value: "e076"},
{ key: "vertical_shades", value: "ec0e"},
{ key: "vertical_shades_closed", value: "ec0d"},
{ key: "vertical_split", value: "e949"},
{ key: "vibration", value: "e62d"},
{ key: "video_call", value: "e070"},
{ key: "video_camera_back", value: "f07f"},
{ key: "video_camera_front", value: "f080"},
{ key: "video_chat", value: "f8a0"},
{ key: "video_collection", value: "e04a"},
{ key: "video_file", value: "eb87"},
{ key: "video_label", value: "e071"},
{ key: "video_library", value: "e04a"},
{ key: "video_settings", value: "ea75"},
{ key: "video_stable", value: "f081"},
{ key: "videocam", value: "e04b"},
{ key: "videocam_off", value: "e04c"},
{ key: "videogame_asset", value: "e338"},
{ key: "videogame_asset_off", value: "e500"},
{ key: "view_agenda", value: "e8e9"},
{ key: "view_array", value: "e8ea"},
{ key: "view_carousel", value: "e8eb"},
{ key: "view_column", value: "e8ec"},
{ key: "view_comfortable", value: "e42a"},
{ key: "view_comfy", value: "e42a"},
{ key: "view_comfy_alt", value: "eb73"},
{ key: "view_compact", value: "e42b"},
{ key: "view_compact_alt", value: "eb74"},
{ key: "view_cozy", value: "eb75"},
{ key: "view_day", value: "e8ed"},
{ key: "view_headline", value: "e8ee"},
{ key: "view_in_ar", value: "e9fe"},
{ key: "view_kanban", value: "eb7f"},
{ key: "view_list", value: "e8ef"},
{ key: "view_module", value: "e8f0"},
{ key: "view_quilt", value: "e8f1"},
{ key: "view_sidebar", value: "f114"},
{ key: "view_stream", value: "e8f2"},
{ key: "view_timeline", value: "eb85"},
{ key: "view_week", value: "e8f3"},
{ key: "vignette", value: "e435"},
{ key: "villa", value: "e586"},
{ key: "visibility", value: "e8f4"},
{ key: "visibility_off", value: "e8f5"},
{ key: "voice_chat", value: "e62e"},
{ key: "voice_over_off", value: "e94a"},
{ key: "voicemail", value: "e0d9"},
{ key: "volcano", value: "ebda"},
{ key: "volume_down", value: "e04d"},
{ key: "volume_down_alt", value: "e79c"},
{ key: "volume_mute", value: "e04e"},
{ key: "volume_off", value: "e04f"},
{ key: "volume_up", value: "e050"},
{ key: "volunteer_activism", value: "ea70"},
{ key: "vpn_key", value: "e0da"},
{ key: "vpn_key_off", value: "eb7a"},
{ key: "vpn_lock", value: "e62f"},
{ key: "vrpano", value: "f082"},
{ key: "wallet", value: "f8ff"},
{ key: "wallet_giftcard", value: "e8f6"},
{ key: "wallet_membership", value: "e8f7"},
{ key: "wallet_travel", value: "e8f8"},
{ key: "wallpaper", value: "e1bc"},
{ key: "warehouse", value: "ebb8"},
{ key: "warning", value: "e002"},
{ key: "warning_amber", value: "f083"},
{ key: "wash", value: "f1b1"},
{ key: "watch", value: "e334"},
{ key: "watch_later", value: "e924"},
{ key: "watch_off", value: "eae3"},
{ key: "water", value: "f084"},
{ key: "water_damage", value: "f203"},
{ key: "water_drop", value: "e798"},
{ key: "waterfall_chart", value: "ea00"},
{ key: "waves", value: "e176"},
{ key: "waving_hand", value: "e766"},
{ key: "wb_auto", value: "e42c"},
{ key: "wb_cloudy", value: "e42d"},
{ key: "wb_incandescent", value: "e42e"},
{ key: "wb_iridescent", value: "e436"},
{ key: "wb_shade", value: "ea01"},
{ key: "wb_sunny", value: "e430"},
{ key: "wb_twighlight", value: "ea02"},
{ key: "wb_twilight", value: "e1c6"},
{ key: "wc", value: "e63d"},
{ key: "web", value: "e051"},
{ key: "web_asset", value: "e069"},
{ key: "web_asset_off", value: "e4f7"},
{ key: "web_stories", value: "e595"},
{ key: "webhook", value: "eb92"},
{ key: "wechat", value: "ea81"},
{ key: "weekend", value: "e16b"},
{ key: "west", value: "f1e6"},
{ key: "whatshot", value: "e80e"},
{ key: "wheelchair_pickup", value: "f1ab"},
{ key: "where_to_vote", value: "e177"},
{ key: "widgets", value: "e1bd"},
{ key: "width_full", value: "f8f5"},
{ key: "width_normal", value: "f8f6"},
{ key: "width_wide", value: "f8f7"},
{ key: "wifi", value: "e63e"},
{ key: "wifi_1_bar", value: "e4ca"},
{ key: "wifi_2_bar", value: "e4d9"},
{ key: "wifi_calling", value: "ef77"},
{ key: "wifi_calling_3", value: "f085"},
{ key: "wifi_channel", value: "eb6a"},
{ key: "wifi_find", value: "eb31"},
{ key: "wifi_lock", value: "e1e1"},
{ key: "wifi_off", value: "e648"},
{ key: "wifi_password", value: "eb6b"},
{ key: "wifi_protected_setup", value: "f0fc"},
{ key: "wifi_tethering", value: "e1e2"},
{ key: "wifi_tethering_error", value: "ead9"},
{ key: "wifi_tethering_error_rounded", value: "f086"},
{ key: "wifi_tethering_off", value: "f087"},
{ key: "wind_power", value: "ec0c"},
{ key: "window", value: "f088"},
{ key: "wine_bar", value: "f1e8"},
{ key: "woman", value: "e13e"},
{ key: "woman_2", value: "f8e7"},
{ key: "woo_commerce", value: "ea6d"},
{ key: "wordpress", value: "ea9f"},
{ key: "work", value: "e8f9"},
{ key: "work_history", value: "ec09"},
{ key: "work_off", value: "e942"},
{ key: "work_outline", value: "e943"},
{ key: "workspace_premium", value: "e7af"},
{ key: "workspaces", value: "e1a0"},
{ key: "workspaces_filled", value: "ea0d"},
{ key: "workspaces_outline", value: "ea0f"},
{ key: "wrap_text", value: "e25b"},
{ key: "wrong_location", value: "ef78"},
{ key: "wysiwyg", value: "f1c3"},
{ key: "yard", value: "f089"},
{ key: "youtube_searched_for", value: "e8fa"},
{ key: "zoom_in", value: "e8ff"},
{ key: "zoom_in_map", value: "eb2d"},
{ key: "zoom_out", value: "e900"},
{ key: "zoom_out_map", value: "e56b"}
]
export default materialIconsCodePoints