import { useState } from "react"
import { Link } from "react-router-dom"
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import bgImage from "assets/images/openai/image4.jpg";
import globalSettings  from "context/globals"
import MDSnackbar from "components/MDSnackbar";
import MDAlert from "components/MDAlert";
import CircularProgress from '@mui/material/CircularProgress'

function Cover(): JSX.Element {
  const [usernameOrEmail, setUsernameOrEmail] = useState()
  const [email, setEmail] = useState()
  const [isAttemptingToSendPasswordResetCode, setIsAttemptingToSendPasswordResetCode] = useState(false)
  const [isToDisplaySnackBarError, setIsToDisplaySnackBarError] = useState(false);
  const [isToDisplaySnackBarSuccess, setIsToDisplaySnackBarSuccess] = useState(false);
  const toggleisToDisplaySnackBarError = () => setIsToDisplaySnackBarError(!isToDisplaySnackBarError);
  
  const handleSetUsernameOrEmail = (event : any) => {
    setIsToDisplaySnackBarError(false)
    setIsToDisplaySnackBarSuccess(false)
    setUsernameOrEmail(event.target.value)
  }
  const handleSendPasswordResetCodeClick = () => {
    console.log('handleLoginClick')
    setIsAttemptingToSendPasswordResetCode(true)
    setIsToDisplaySnackBarSuccess(false)
    setIsToDisplaySnackBarError(false)
    //setDidSigninFail(false)
    fetch(`${globalSettings.apiBaseUrl}/api/account/sendpasswordresetcode`, {
        credentials: "include",
        method: "put",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          usernameOrEmail : usernameOrEmail
        })
      })
        .then(Response => Response.json())
        .then(response => {
          setIsAttemptingToSendPasswordResetCode(false)
          if(response.responseCode === 1000) {
            setIsToDisplaySnackBarSuccess(true)
          } else {
            setIsToDisplaySnackBarError(true)  
          }
        })
    }
    const handleKeyDown = (e : any) => {
      if(e.keyCode === 13) { // enter key
        handleSendPasswordResetCodeClick()
      }
    }

  
  return (
    <CoverLayout coverHeight="50vh" image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          py={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
            Forgot Password?
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
          Enter your email to search for your account.
          </MDTypography>  
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={4}>
            {!isToDisplaySnackBarSuccess && (
            <MDInput
              type="text"
              label="Username or Email"
              variant="outlined"
              fullWidth
              value={usernameOrEmail}
              onChange={handleSetUsernameOrEmail}
              onKeyDown={handleKeyDown}
              disabled={isAttemptingToSendPasswordResetCode}
              />
            )}
            {isToDisplaySnackBarSuccess && (
              <MDBox mt={6} mb={1}>
                <MDAlert color="success">A reset password email has been sent. Check your 'spam' folder as well.</MDAlert>
              </MDBox>
            )}
            </MDBox>
            <MDBox mt={6} mb={1}>
            {!isToDisplaySnackBarSuccess && (
              <MDButton
                variant="gradient"
                color="info"
                fullWidth
                onClick={handleSendPasswordResetCodeClick}
                disabled={isAttemptingToSendPasswordResetCode}
                >
                {isAttemptingToSendPasswordResetCode ? <CircularProgress size={20} sx={{ color: "#fff" }}/> : `Send password reset link`}
              </MDButton>
              )}
            </MDBox>
            <MDTypography
                    component={Link}
                    to="/sign-in"
                    variant="button"
                    color="info"
                    fontWeight="medium"
                    textGradient
                  >
                Back to sign in
              </MDTypography>
            {isToDisplaySnackBarError && (
              <MDBox mt={6} mb={1}>
                <MDAlert color="error">Sorry, but that email does not exist.</MDAlert>
              </MDBox>
            )}
          </MDBox>
        </MDBox>
      </Card>
      <MDSnackbar
        color="error"
        icon="notifications"
        title="Reset Your Password"
        content="Sorry, but that email does not exist."
        dateTime=""
        open={isToDisplaySnackBarError}
        close={toggleisToDisplaySnackBarError}
      />
    </CoverLayout>
  );
}

export default Cover;
