// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect } from "react"
import Checkbox from "@mui/material/Checkbox"
import Check from "@mui/icons-material/Check"
import PropTypes from "prop-types"
import FormControlLabel from "@mui/material/FormControlLabel"
import TextField from "@mui/material/TextField"
import materialIconsCodePoints from "./MaterialIcons-Regular" // Relative path to your File
import Grid from "@mui/material/Grid"

interface qrInfoSymbolsChooserProps {
  symbolUnicode?: string;
  hexColor?: string;
  isSelected?: boolean;
  title?: string;
  onSymbolChange?: (arg0: string, arg1: string) => void;
}
  
function QRInfoSymbolsChooser(props : qrInfoSymbolsChooserProps): JSX.Element {

  const [symbol, setSymbol] = useState(props.symbolUnicode)
  const handleSymbolChange = (key: string, value: string) => {
    setSymbol(value)
    props.onSymbolChange(key, value)
  }
  
  const icons = materialIconsCodePoints.map( (item) => {
    let colorString : string = `blue`
    let boxShadowString: string = "inherit"
    if(symbol === item.value) {
      colorString = "red"
      boxShadowString = "0px 0px 0px 1px red inset"
    } else {
      if(props.hexColor) {
        colorString = `#${props.hexColor}`
      } else {
        colorString = `black`
      }
    }
    return (
      <Grid item md={2} style={{textAlign: "center", boxShadow: boxShadowString}}>
        <span onClick={() => handleSymbolChange(item.key, item.value)} style={{color:colorString}}>{item.key}</span>
      </Grid>
  )})
  // Component Render
  return (
      <>
        <div style={{fontFamily:"Material Icons Round", fontSize:"50px", overflow:"auto", maxHeight: "500px"}} > 
        <Grid container>
        {icons}
        </Grid>
        </div>
      </>
      
  )
}

// Default Export
export default (QRInfoSymbolsChooser)
