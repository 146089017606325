const Enums = {
    DaysofTheWeek: {
      SUNDAY: 0,
      MONDAY: 1,
      TUESDAY: 2,
      WEDNESDAY: 3,
      THURSDAY: 4,
      FRIDAY: 5,
      SATURDAY: 6
    },
    Organizations: {
      Imatari: 1,
      AutoQuestLLC: 2,
      BellevueFloristAndCafe: 3,
      SeattleMariners: 4
    },
    FeatureFlags: {
      QRManager1: 2,
      QRManager2: 3,
      DashboardSales: 4
    },
    ResponseCodes: {
      SUCCESS: 1000,
      Failure: 1001,
      LoginSuccess: 1002,
      LoginFailed: 1003,
      LoginAccountDidNotMatchToken: 1004,
      UsernameAlreadyExists: 1005,
      EmailAlreadyExists: 1006,
      AccountDoesNotExist: 1007,
      InvalidPassword: 1008,
      AccountLocked: 1009,
      AccountIsNotActive: 1010,
      AccountIsPending: 1011,
      AccountAlreadyExists: 1012,
      UnknownIssueWhenSigningUp: 1013,
      InvalidCAPTCHA: 1014,
    },
    Permissions: {
      CanSeeGoogleoAuthSignUp: "CanSeeGoogleoAuthSignUp",
      CanSeeFacebookoAuthSignUp: "CanSeeFacebookoAuthSignUp"
    },
    QRCodeTypes: {
      URL: 1,
      VCARD: 2,
      SMS: 3,
      TEXT: 4
    }
  }
  
  export default Object.freeze(Enums)
  