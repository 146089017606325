import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import IconButton from '@mui/material/IconButton'
import CoverLayout from "layouts/authentication/components/CoverLayout"
import bgImage from "assets/images/openai/image3.jpg"
import globalSettings  from "context/globals"
import { getCookie, setCookie, removeCookie } from 'typescript-cookie'
import { CircularProgress } from '@mui/material'
import MDAlert from "components/MDAlert"
import { useMaterialUIController, setiToken, setCurrentAccount, setLayout } from "context";
import { Navigate } from "react-router-dom";
import InputAdornment from '@mui/material/InputAdornment'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import FacebookIcon from "@mui/icons-material/Facebook"
import Grid from "@mui/material/Grid"
import MuiLink from "@mui/material/Link"
import { GoogleOAuthProvider } from '@react-oauth/google'
import { GoogleLogin } from '@react-oauth/google';
import Enums from "context/Enums"
import { jwtDecode } from "jwt-decode"
import './cover.css'

const h1Styles = {
  backgroundColor: "rgba(255, 255, 255, 0.85)",
  position: "absolute",
  right: 0,
  bottom: "2rem",
  padding: "0.5rem",
  fontFamily: "sans-serif",
  fontSize: "1.5rem",
  boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
};
interface SignInCoverProps {
  isForcedLogout?: boolean
}

function Cover(props: SignInCoverProps): JSX.Element {
  const [controller, dispatch] = useMaterialUIController();
  const {
    currentAccount,
    currentFeatureFlags
  } = controller;
  const [iTokenCookie, setiTokenCookie] = useState(getCookie('itoken'))
  const [usernameOrEmail, setUsernameOrEmail] = useState(getCookie('usernameOrEmail'))
  const [rememberMe, setRememberMe] = useState(true)
  const [password, setPassword] = useState('')
  const [isSignedIn, setIsSignedIn] = useState(false)
  const [isAttemptingToSignIn, setIsAttemptingToSignIn] = useState(false)
  const [didSigninFail, setDidSigninFail] = useState(false)
  const [isToShowPassword, setIsToShowPassword] = useState(false);
  const [googleUser, setGoogleUser] = useState<any>({});
  const [googleUserProfile, setGooogleUserProfile] = useState<any>({});
  const [googleSub, setGooogleSub] = useState<string>();
  
  const handleClickShowPassword = () => setIsToShowPassword((isToShow) => !isToShow);
  const handleSetRememberMe = () =>  setRememberMe(!rememberMe)
  const handleSetUsernameOrEmail = (event : any) => {
    setDidSigninFail(false)
    setCookie("usernameOrEmail", event.target.value, { path: '/', domain: `${globalSettings.cookieDomain}` })
    setUsernameOrEmail(event.target.value)
  }
  const handleSetPassword = (event : any) => {
    setDidSigninFail(false)
    setPassword(event.target.value)
  }
  const handleIsSignedIn = () => setIsSignedIn(!isSignedIn)
  const handleSignOutClick = () => {
    setiTokenCookie("")
    removeCookie("itoken", { path: '/', domain: `${globalSettings.cookieDomain}` })
  }
  const navigate = useNavigate();

  const handleKeyDown = (e : any) => {
    if(e.keyCode === 13) { // enter key
      handleSignInClick()
    }
  }

  const handleSignInClick = () => {
    console.log('handleLoginClick')
    setIsAttemptingToSignIn(true)
    setDidSigninFail(false)
    fetch(`${globalSettings.apiBaseUrl}/api/account/login`, {
        credentials: "include",
        method: "put",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          usernameOrEmail : usernameOrEmail,
          password : password,
          googleSub: googleSub
        })
      })
        .then(Response => Response.json())
        .then(response => {
          //alert(JSON.stringify(response))
          setIsAttemptingToSignIn(false)
          if(response.responseCode !== 1002) {
            setDidSigninFail(true)
          } else {
            setiTokenCookie(response.content?.loginToken)
            setiToken(dispatch, response.content?.loginToken)
            setCurrentAccount(dispatch, response.content)
            setDidSigninFail(false)
            setLayout(dispatch, "dashboard")
            navigate("/dashboard/analytics")
          }
        })
    }

  const handleGoolgeoAuthSuccess = (response : any) => {
    // If here, that means the Google user oAuth authorized the QQWIK client id: (470161660563-8lguitu73v454ojc32shsetbhpbgrt1o.apps.googleusercontent.com)
    // access to their account.  The Google "sub" is the globally unique id that we store in the [account] table.
    console.log(`handleGoolgeoAuthSuccess: ${JSON.stringify(response)}`)
    const jwtToken = response.credential
    const decoded : any = jwtDecode(jwtToken);
    console.log(`decode JWT token: ${JSON.stringify(decoded)}`)
    setUsernameOrEmail(decoded.email)
    setGooogleSub(decoded.sub);
    //setGoogleUser(response)
  }

  useEffect( () => {
    if(googleSub) {
      handleSignInClick();
    }
  }, [googleSub])

  const handleGoolgeoAuthError = () => {
    alert('Google oAuth error occurred, try again later.')
  }
  
  useEffect( () => {
    console.log(`sign-in/cover/index.tsx - permissions: ${JSON.stringify(currentAccount?.permissions)}, feature flags: ${JSON.stringify(currentFeatureFlags)}, currentFeatureFlags.GoogleoAuthSignIn = ${currentFeatureFlags?.GoogleoAuthSignIn}`)
    if(props.isForcedLogout) {
      handleSignOutClick()
    }  
  }, [])

  useEffect(
    () => {
      return
        if (googleUser) {
          fetch(`https://www.googleapis.com/oauth2/v3/userinfo?access_token=${googleUser.credential}`, {
            credentials: "include",
            method: "get",
            headers: {
              Authorization: `Bearer ${googleUser.credential}`,
              Accept: "application/json",
            }
          })
            .then((response : any) => {
              setGooogleUserProfile(response.data)
            })
            .catch( (error) => console.log(error))
        }
    },
    [ googleUser ]
);

  return (
    <CoverLayout image={bgImage}>
        
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1} mb={1}>
            Sign in
          </MDTypography>
          <Grid container spacing={3} justifyContent="center">
            {(currentAccount?.permissions?.includes("CanSeeFacebookoAuthSignUp") || currentFeatureFlags.FacebookoAuthSignIn) &&  (
              <Grid item>
                <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                  <FacebookIcon color="inherit" />
                </MDTypography>
              </Grid>
            )}
            {(currentAccount?.permissions?.includes("CanSeeGoogleoAuthSignUp") || currentFeatureFlags.GoogleoAuthSignIn) &&  (
              <Grid item>
              <GoogleOAuthProvider clientId="470161660563-8lguitu73v454ojc32shsetbhpbgrt1o.apps.googleusercontent.com">
                <GoogleLogin onSuccess={handleGoolgeoAuthSuccess} onError={handleGoolgeoAuthError} type="standard" size="medium" text="signin_with"/>
              </GoogleOAuthProvider>
              </Grid>
            )}
          </Grid>
        </MDBox>
        <MDTypography display="block" variant="button" color="white" mt={1} mb={1}>
            <div className="separator">or</div>
        </MDTypography>
        <MDBox pt={4} pb={3} px={3}>
        {iTokenCookie &&
               <MDTypography variant="button" color="dark">
               Continue as {" "}
               <MDTypography
                 component={Link}
                 to="/dashboard/analytics"
                 variant="button"
                 color="info"
                 fontWeight="medium"
                 textGradient
               >
                 {usernameOrEmail}
               </MDTypography><br/>
               Not {usernameOrEmail}?&nbsp;
               <MDTypography
                  component={Link}
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                  onClick={handleSignOutClick}
                >
                  Switch accounts.
                </MDTypography>
             </MDTypography>
              }
          
          <MDBox component="form" role="form">
          {!iTokenCookie &&
        <>
          <MDBox mb={2}>
              <MDInput
                type="email"
                label="Username or Email"
                variant="outlined"
                fullWidth
                placeholder="john@example.com"
                InputLabelProps={{ shrink: true }}
                value={usernameOrEmail}
                onChange={handleSetUsernameOrEmail}
                onKeyDown={handleKeyDown}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type={isToShowPassword ? 'text' : 'password'}
                label="Password"
                variant="outlined"
                fullWidth
                placeholder="************"
                InputLabelProps={{ shrink: true }}
                onChange={handleSetPassword}
                onKeyDown={handleKeyDown}
                InputProps={password.length > 0 && {
                  endAdornment: 
                  <InputAdornment position="end" sx={{padding:"20px"}}>
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {isToShowPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                  }}
              />
            </MDBox>
            <MDBox mb={2} textAlign="center">
              <MDTypography
                    component={Link}
                    to="/reset-password"
                    variant="button"
                    color="info"
                    fontWeight="medium"
                    textGradient
                  >
                Forgot Password?
              </MDTypography>
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Keep me signed in?
              </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={1}>
              
              <MDButton variant="gradient" color="info" fullWidth onClick={handleSignInClick} disabled={isAttemptingToSignIn}>
                {isAttemptingToSignIn ? <>Sign In <CircularProgress size={20} sx={{ color: "#fff" }}/></> : `Sign In`}
              </MDButton>
              {didSigninFail &&
                <MDAlert color="error" dismissible>Signin Failed</MDAlert>
              }
            </MDBox>
            </>
        }
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="dark">
                Need a QQWIK account?{" "}
                <MDTypography
                  component={Link}
                  to="/sign-up"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign up
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </CoverLayout>
  );
}

export default Cover;
