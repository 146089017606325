import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

function OrganizationManager(): JSX.Element {
    return (
        <DashboardLayout>
            <DashboardNavbar />
        
            <>Not yet implemented</>
        </DashboardLayout> 
    )
}

export default OrganizationManager