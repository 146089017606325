import { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import Edit from "@mui/icons-material/Edit"
import Analytics from "@mui/icons-material/Analytics"
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import globalSettings  from "context/globals"
import { Link } from "react-router-dom"
import { useMaterialUIController } from "context";
import Helpers from "context/Helpers"

interface QRCodeManagerProps {
  // form?: string;
  isDynamic: boolean;
  // icon?: React.ReactElement<SvgIconProps>;
  // type: string;
  // onClick: () => void;
  // text: string;
  //qrCodeId?: number
  //someValue?: string
  //sendDataToParent?: (arg0: number) => void
 }

function QRCodeManager(props: QRCodeManagerProps): JSX.Element {
  const [controller] = useMaterialUIController();
  const { direction, currentQRCode, currentOrganization, dispatch } = controller;
  
  const [menu, setMenu] = useState(null);
  const openMenu = (event: any) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);
  const [rows, setRows] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [dataTableData, setDataTableData] = useState({columns:[ {Header: "id", accessor: "id"} ], rows: rows})
  const renderMenu = (
    <Menu
      anchorEl={menu}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(menu)}
      onClose={closeMenu}
      keepMounted
    >
      <MenuItem onClick={closeMenu}>Status: Archived</MenuItem>
      <Divider sx={{ margin: "0.5rem 0" }} />
      <MenuItem onClick={closeMenu}>
        <MDTypography variant="button" color="error" fontWeight="regular">
          Remove Filter
        </MDTypography>
      </MenuItem>
    </Menu>
  )

  // Hard code the expected columns
  const columns = [ 
    {Header: "Actions", accessor: "actions",
      Cell: ({ row }: any) => <>
      <Link to={`/qrcodes/manager/new-qrcode?qrcodeid=${row.original?.id}`}>
        <MDButton variant="text" color="info">
          <Edit />
        </MDButton>
      </Link>
      {row.original.isDynamic &&
      <Link to={`/qrcodes/analyticsmanager?qrcodeid=${row.original?.id}`}>
        <MDButton variant="text" color="info">
          <Analytics />
        </MDButton>
      </Link>
    }
      </>,
      width: "75px"
    },
    {Header: "Name", accessor: "name"}, 
    {Header: "Value", accessor: "value",
    Cell: ({value} : any) => <span title={value}>{Helpers.TruncateString(value)}</span>
    }, 
    {Header: "QR Code", 
    accessor: "nothing",
    disableSortBy: true,
    enableSorting: false,
    Cell: ({ row }: any) => <>
      <img src={`${globalSettings.qrBaseUrl}/qr/${row.original?.token}`} style={{width:"60px"}} alt="QR Code"/>
      </>,
      width: "100px"
    }, 
    {Header: "Audit Info", accessor: "auditInfo",
      Cell: ({value} : any) => <>{Helpers.RenderAuditInfoCell(value)}</>
    }
  ]
  useEffect( () => {
    setDataTableData({columns, rows: []})
  }, [])

  useEffect(() => {
    reloadTableData()
  }, [])


  useEffect(() => {
    reloadTableData()
  }, [currentOrganization.id])


  const reloadTableData = () => {
    /* const initialState = {
      initialState: {
        sorting: {
          sortModel: [{ field: 'auditInfo', sort: 'desc' }],
        }
      },
    }; */

    setIsLoading(true)
    setDataTableData({ columns, rows: []})
    fetch(`${globalSettings.apiBaseUrl}/api/qrcode/retrieveall?organizationid=${currentOrganization.id}`, {
      credentials: "include",
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    })
      .then(Response => Response.json())
      .then(response => {
        //alert(`response: ${JSON.stringify(response)}`)
       // alert(`rows: ${JSON.stringify(dataTableData.rows)}`)
       // dataTableData.rows = response.content
       
       setDataTableData({ columns, rows: response.content})
       setIsLoading(false)
       /*
        setIsAttemptingToSignIn(false)
        if(response.responseCode !== 1002) {
          setDidSigninFail(true)
        } else {
          setitokenCookie(response.content?.loginToken)
          setDidSigninFail(false)   
        }*/
      })
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
          <Link to={`/qrcodes/manager/new-qrcode?qrcodeid=0&isdynamic=${props.isDynamic ? "1" : "0"}`}>
            <MDButton variant="gradient" color="info">
              new qr code
            </MDButton>
          </Link>
          <MDBox display="flex">
            {/*<MDButton variant={menu ? "contained" : "outlined"} color="dark" onClick={openMenu}>
              filters&nbsp;
              <Icon>keyboard_arrow_down</Icon>
            </MDButton>
            {renderMenu}
            <MDBox ml={1}>
              <MDButton variant="outlined" color="dark">
                <Icon>description</Icon>
                &nbsp;export csv
              </MDButton>
            </MDBox>
  */}
          </MDBox>
        </MDBox>
        <Card>
          <DataTable
            table={dataTableData}
            entriesPerPage={{defaultValue: 10, entries: [5, 10, 15, 20, 25]}}
            canSearch={true}
            isLoading={isLoading}
           />
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default QRCodeManager;
