// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect } from "react"
import Checkbox from "@mui/material/Checkbox"
import Check from "@mui/icons-material/Check"
import PropTypes from "prop-types"
import FormControlLabel from "@mui/material/FormControlLabel"
import { HexColorPicker } from "react-colorful"
import TextField from "@mui/material/TextField"

interface qrInfoProps {
    colorKey?: string;
    hexColor?: string;
    isSelected?: boolean;
    title?: string;
    onColorPickerChange?: (arg0: string, arg1: string) => void;
    onIsToUseColorChange?: (arg0: boolean) => void
   }

function QRInfoColorPicker(props : qrInfoProps): JSX.Element {
    
  // Variable/State Declaration
  const [isToDisplayColorPicker, setIsToDisplayColorPicker] = useState(false)
  const [isSelected, setIsSelected] = useState(false)
  const [hexColor, setHexColor] = useState('')

  const handleColorChange = (event:any) => {
    let value = event.target.value
    setHexColor(value)
    props.onColorPickerChange(props.colorKey, value)
  }

  const handleColorPickerChange = (value:any) => {
    let hexColor = value.replace("#", "").substring(0, 6)
    setHexColor(hexColor)
    props.onColorPickerChange('color1', hexColor)
  }

  const handleIsToUseColorChange = (event : any, value: boolean) => {
    console.log(`event = ${event}`)
    console.log(`value = ${value}`)
    setIsSelected(value)
    props.onIsToUseColorChange(value)
  }

  useEffect(() => {
    setHexColor(props.hexColor);
  }, [props.hexColor])

  useEffect(() => {
    setIsSelected(props.isSelected);
  }, [props.isSelected])

  // Component Render
  return (
    <div>
      <FormControlLabel
        control={
          <Checkbox
            checked={isSelected}
            onChange={handleIsToUseColorChange}
            checkedIcon={<Check/>}
            icon={<Check/>}
          />
        }
        label={
          <TextField
            label={
              <>
                <span style={{ display: "flex" }}>
                  {props.title}
                  {hexColor && (
                    <>
                      &nbsp;
                      <div
                        style={{
                          backgroundColor: `#${hexColor}`,
                          height: 25,
                          width: 25,
                          border: `1px solid black`
                        }}
                      />
                    </>
                  )}
                </span>
              </>
            }
            fullWidth={true}
            onChange={handleColorChange}
            value={hexColor || ''}
            onFocus={() => setIsToDisplayColorPicker(true)}
            onBlur={() => setIsToDisplayColorPicker(false)}
          />
        }
      />
      {isToDisplayColorPicker && (
        <HexColorPicker
          id="colorPicker1"
          color={`#${hexColor}`}
          onChange={handleColorPickerChange}
          onFocus={() => setIsToDisplayColorPicker(true)}
          onBlur={() => setIsToDisplayColorPicker(false)}
        />
      )}
    </div>
  )
}

// Default Export
export default (QRInfoColorPicker)
