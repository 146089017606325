import { useState } from "react";
import { Link } from "react-router-dom";
import Card from "@mui/material/Card"
import Checkbox from "@mui/material/Checkbox"
import IconButton from '@mui/material/IconButton'
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import bgImage from "assets/images/openai/image5.jpg";
import MDAlert from "components/MDAlert";
import MDSnackbar from "components/MDSnackbar";
import globalSettings  from "context/globals"
import { getCookie, setCookie } from 'typescript-cookie'
import InputAdornment from '@mui/material/InputAdornment'
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { CircularProgress } from '@mui/material'
import Enums from "context/Enums"
import { GoogleOAuthProvider } from '@react-oauth/google'
import { GoogleLogin } from '@react-oauth/google';
import Grid from "@mui/material/Grid"
import MuiLink from "@mui/material/Link"
import FacebookIcon from "@mui/icons-material/Facebook"
import { jwtDecode } from "jwt-decode"
import { useMaterialUIController, setiToken, setCurrentAccount, setLayout } from "context";

function Cover() : JSX.Element {
  const [controller, dispatch] = useMaterialUIController();
  const {
    currentAccount,
    currentFeatureFlags
  } = controller;
  const [itokenCookie, setITokenCookie] = useState(getCookie('itoken'));
  const [username, setUsername] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isAgreeableToTerms, setIsAgreeableToTerms] = useState(false);
  const [displayEmailIsRequired, setDisplayEmailIsRequired] = useState(false);
  const [displayPasswordIsRequired, setDisplayPasswordIsRequired] = useState(false);
  const [displayTermsIsRequired, setDisplayTermsIsRequired] = useState(false);
  const [errorSB, setErrorSB] = useState(false);
  const [isAttemptingToSignUp, setIsAttemptingToSignUp] = useState(false)
  const [isToShowPassword, setIsToShowPassword] = useState(false);
  const [isSignUpSuccessful, setIsSignUpSuccessful] = useState(false);
  const [displayEmailIsAlreadyTaken, setDisplayEmailIsAlreadyTaken] = useState(false);
  const [googleUser, setGoogleUser] = useState<any>({});
  const [googleUserProfile, setGooogleUserProfile] = useState<any>({});
  const [googleSub, setGooogleSub] = useState<string>();
  const [usernameOrEmail, setUsernameOrEmail] = useState(getCookie('usernameOrEmail'))
  
  const handleClickShowPassword = () => setIsToShowPassword((isToShow) => !isToShow);
  const handleSetEmail = (event : any) => {
    setDisplayEmailIsRequired(false)
    setEmail(event.target.value)
  }
  //const handleSetUsername = (event : Event) => {setUsername(event.target.value);}
  const handleSetPassword = (event : any) => {
    setDisplayPasswordIsRequired(false)
    setPassword(event.target.value)
  }
  const handleSetIsAgreeableToTerms = () => {
    setDisplayTermsIsRequired(false)
    setIsAgreeableToTerms(!isAgreeableToTerms)
  }
  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);
  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title="Material Dashboard"
      content="Email is required"
      dateTime="11 mins ago"
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
      bgWhite
    />
  )

  const handleGoolgeoAuthSuccess = (response : any) => {
    // If here, that means the Google user oAuth authorized the QQWIK client id: (470161660563-8lguitu73v454ojc32shsetbhpbgrt1o.apps.googleusercontent.com)
    // access to their account.  The Google "sub" is the globally unique id that we store in the [account] table.
    console.log(`handleGoolgeoAuthSuccess: ${JSON.stringify(response)}`)
    const jwtToken = response.credential
    const decoded : any = jwtDecode(jwtToken);
    console.log(`decode JWT token: ${JSON.stringify(decoded)}`)
    setUsernameOrEmail(decoded.email)
    setGooogleSub(decoded.sub);
    //setGoogleUser(response)
  }

  const handleSignUpClick = () => {
    console.log('handleSignUpClick')
    let isValidated = true;
    if(email.length < 5 || email.indexOf('@') === -1 || email.indexOf('.') === -1) {
      isValidated = false
      setDisplayEmailIsRequired(true)
      //openErrorSB()
    } else {
      setDisplayEmailIsRequired(false)
    }
    if(password === '' || password.length < 5) {
      isValidated = false
      setDisplayPasswordIsRequired(true)
      //openErrorSB()
    } else {
      setDisplayPasswordIsRequired(false)
    }
    if(isAgreeableToTerms === false) {
      isValidated = false
      setDisplayTermsIsRequired(true)
    } else {
      setDisplayTermsIsRequired(false)
    }
    if(isValidated) {
      setIsAttemptingToSignUp(true)
      setDisplayEmailIsAlreadyTaken(false)
      fetch(`${globalSettings.apiBaseUrl}/api/account/signup`, {
          credentials: "include",
          method: "put",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            username: username,
            email: email,
            password : password
          })
        })
          .then(Response => Response.json())
          .then(response => {
            //alert(JSON.stringify(response))
            setIsAttemptingToSignUp(false)
            switch(response.responseCode) {
              case Enums.ResponseCodes.SUCCESS:
                setIsSignUpSuccessful(true)
                setCookie("usernameOrEmail", email, { path: '/', domain: `${globalSettings.cookieDomain}` })
                break;
              case Enums.ResponseCodes.EmailAlreadyExists:
                setIsSignUpSuccessful(false)
                setDisplayEmailIsAlreadyTaken(true)
                break;
            }
          })
          .catch( error => {
            alert(`Error occurred: ${error}`)
            setIsAttemptingToSignUp(false)
          })
    }
  }

  const handleGoolgeoAuthError = () => {
    alert('Google oAuth error occurred, try again later.')
  }

  return (
    <CoverLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Signing up is free
          </MDTypography>
          <Grid container spacing={3} justifyContent="center" mt={1}>
            {(currentAccount?.permissions?.includes("CanSeeFacebookoAuthSignUp") || currentFeatureFlags.FacebookoAuthSignUp) &&  (
              <Grid item xs={12}>
                <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                  <FacebookIcon color="inherit" />
                </MDTypography>
              </Grid>
            )}
            {(currentAccount?.permissions?.includes("CanSeeGoogleoAuthSignUp") || currentFeatureFlags.GoogleoAuthSignUp) &&  (
              <Grid item xs={12}>
              <GoogleOAuthProvider clientId="470161660563-8lguitu73v454ojc32shsetbhpbgrt1o.apps.googleusercontent.com">
                <GoogleLogin onSuccess={handleGoolgeoAuthSuccess} onError={handleGoolgeoAuthError} type="standard" size="medium" text="signup_with"/>
              </GoogleOAuthProvider>
              </Grid>
            )}
          </Grid>
        </MDBox>
        <MDTypography display="block" variant="button" color="white" mt={1} mb={0}>
            <div className="separator">or</div>
        </MDTypography>
        <MDBox pt={4} pb={3} px={3}>
          {isSignUpSuccessful && (
              <MDBox mb={2}>
                <MDTypography variant="button" color="dark">
                  Congratulations, your new account for email, {email}, has been created.  Use your account to {" "}
                <MDTypography
                  component={Link}
                  to="/sign-in"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  sign in.
                </MDTypography>
                </MDTypography>
              </MDBox>
          )}
          {!isSignUpSuccessful && (
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                type="email"
                label="Email"
                variant="outlined"
              fullWidth onChange={handleSetEmail} error={displayEmailIsRequired || displayEmailIsAlreadyTaken} required={true}/>
              {renderErrorSB}
            </MDBox>
            <MDBox mb={2}>
              <MDInput 
              type={isToShowPassword ? 'text' : 'password'}
              label="Password"
              variant="outlined"
              InputProps={{
                endAdornment:
                <InputAdornment position="end" sx={{padding:"20px"}}>
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {isToShowPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }}
              fullWidth 
              onChange={handleSetPassword}
              error={displayPasswordIsRequired}
              required={true}/>
              </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Checkbox onChange={handleSetIsAgreeableToTerms} checked={isAgreeableToTerms}/>
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;I agree to the&nbsp;
              </MDTypography>
              <MDTypography
                component="a"
                href="https://www.qqwik.com/eula"
                variant="button"
                fontWeight="bold"
                color="info"
                textGradient
                target="_new"
              >
                Terms and Conditions
              </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" fullWidth onClick={handleSignUpClick} disabled={isAttemptingToSignUp}>
              {isAttemptingToSignUp ? <CircularProgress size={20} sx={{ color: "#fff" }}/> : `Sign Up`}
              </MDButton>
            </MDBox>
            {displayEmailIsAlreadyTaken &&
              <MDAlert color="error" variant="h1">Email is already registered</MDAlert>
            }
            {displayEmailIsRequired &&
              <MDAlert color="error" variant="h1">Email is required</MDAlert>
            }
            
            {displayPasswordIsRequired &&
                <MDAlert color="error">Password is required</MDAlert>
              }
           
            {displayTermsIsRequired &&
                <MDAlert color="error">Please agree to the terms & conditions</MDAlert>
              }
            
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Already have an account?{" "}
                <MDTypography
                  component={Link}
                  to="/sign-in"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign In
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        )}
        </MDBox>
      </Card>
    </CoverLayout>
  );
}

export default Cover;