import { useEffect } from "react";
import { Link } from "react-router-dom";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import CoverLayout from "layouts/authentication/components/CoverLayout"
import bgImage from "assets/images/openai/image3.jpg"
import { removeCookie } from 'typescript-cookie'
import globalSettings  from "context/globals"

function Cover(): JSX.Element {
  useEffect( () => {
    removeCookie("itoken", { path: '/', domain: `${globalSettings.cookieDomain}` })
  }, [])

  return (
    <CoverLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign out
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            You have been signed out
          </MDTypography>
        </MDBox>
        <MDBox mt={3} mb={1} pb={3} textAlign="center">
            <MDTypography variant="button" color="dark">
            Want to sign back in?{" "}
            <MDTypography
                component={Link}
                to="/sign-in"
                variant="button"
                color="info"
                fontWeight="medium"
                textGradient
            >
                Sign In
            </MDTypography>
            </MDTypography>
        </MDBox>
      </Card>
    </CoverLayout>
  );
}

export default Cover;
