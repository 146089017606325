import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import Edit from "@mui/icons-material/Edit"
import Analytics from "@mui/icons-material/Analytics"
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import globalSettings  from "context/globals"
import { Link } from "react-router-dom"
import { useMaterialUIController } from "context"
import TimeAgo from 'react-timeago'
import Helpers from "context/Helpers"

function AnalyticsManager(): JSX.Element {
  const [searchParams] = useSearchParams();
  const [controller] = useMaterialUIController();
  const { direction, currentQRCode, currentOrganization, dispatch } = controller;
  
  const [menu, setMenu] = useState(null);
  const openMenu = (event: any) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);
  const [rows, setRows] = useState([])
  const [dataTableData, setDataTableData] = useState({columns:[ {Header: "id", accessor: "id"} ], rows: [{"id": 2}]})
  const renderMenu = (
    <Menu
      anchorEl={menu}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(menu)}
      onClose={closeMenu}
      keepMounted
    >
      <MenuItem onClick={closeMenu}>Status: Paid</MenuItem>
      <MenuItem onClick={closeMenu}>Status: Refunded</MenuItem>
      <MenuItem onClick={closeMenu}>Status: Canceled</MenuItem>
      <Divider sx={{ margin: "0.5rem 0" }} />
      <MenuItem onClick={closeMenu}>
        <MDTypography variant="button" color="error" fontWeight="regular">
          Remove Filter
        </MDTypography>
      </MenuItem>
    </Menu>
  )

  // Hard code the expected columns
  const columns = [ 
    {
        Header: "Date/Time", accessor: "requestDateTime", 
        width: "100px",
        Cell: ({value} : any) => 
         <>   <TimeAgo
                      date={value}
                      title={Helpers.PrettyDateTimeinPacificTimeZone(
                        value
                      )}
                    />
                    </>
    },
    {Header: "Remote IP", accessor: "remoteIP"},
    {Header: "Location", accessor: "location",
      Cell: ({row} : any) => <>
        {row.original.ipAddressGeo?.city}, {row.original.ipAddressGeo?.region} - {row.original.ipAddressGeo?.postalCode}
        <br/>({row.original.ipAddressGeo?.country})
      </>
    },
    {Header: "User Agent String", accessor: "userAgentString"}, 
    {
      Header: "Cookies", accessor: "cookies", 
      width: "100px"
    },
    {Header: "iGUID", accessor: "iGUID"},
    
  ]
  useEffect( () => {
    setDataTableData({columns, rows: []})
  }, [])

  useEffect( () => {
    if(searchParams.get("qrcodeid")) {
      reloadTableData(searchParams.get("qrcodeid"))
    }
  }, [])

  const reloadTableData = (qrCodeId : any) => {
    fetch(`${globalSettings.apiBaseUrl}/api/qrcode/scanmetrics?qrcodeid=${qrCodeId}`, {
      credentials: "include",
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    })
      .then(Response => Response.json())
      .then(response => {
        //alert(`response: ${JSON.stringify(response)}`)
       // alert(`rows: ${JSON.stringify(dataTableData.rows)}`)
       // dataTableData.rows = response.content
       
       setDataTableData({ columns, rows: response.content})
       /*
        setIsAttemptingToSignIn(false)
        if(response.responseCode !== 1002) {
          setDidSigninFail(true)
        } else {
          setitokenCookie(response.content?.loginToken)
          setDidSigninFail(false)   
        }*/
      })
  }


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
          Analytics for QR Code ({searchParams.get("qrcodeid")}) {/*- Total Scans: , Unique Scans: 

          Scans by Date of Week, Scans by Month, Scans by State, Scans by Postal Code

  Scans by Browser, Scans by Operating Systems, Scans by Country.*/}
          <MDBox display="flex">
          {/*  <MDButton variant={menu ? "contained" : "outlined"} color="dark" onClick={openMenu}>
              filters&nbsp;
              <Icon>keyboard_arrow_down</Icon>
            </MDButton>
            {renderMenu}
            <MDBox ml={1}>
              <MDButton variant="outlined" color="dark">
                <Icon>description</Icon>
                &nbsp;export csv
              </MDButton>
            </MDBox>
*/}
          </MDBox>
        </MDBox>
        <Card>
          <DataTable table={dataTableData} entriesPerPage={false} canSearch />
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default AnalyticsManager;
