let globalSettings = {
    version: "1.1.5",
    cookieDomain: "localhost",
    cdnBaseUrl: "https://cdn.qqwik.com",
    apiBaseUrl: "https://localhost:7034",
    portalBaseUrl: "https://localhost:3000",
    qrBaseUrl: "https://localhost:7034"
    //captchaSiteKey: window._env_.REACT_APP_CAPTCHA_SITE_KEY
  }

if(window.location.href.indexOf("portal.qqwik.com") !== -1) {
    globalSettings.cookieDomain = ".qqwik.com"
    globalSettings.apiBaseUrl = "https://api.qqwik.com"
    globalSettings.portalBaseUrl = "https://portal.qqwik.com"
    globalSettings.qrBaseUrl = "https://qr.qqwik.com"
}

export default globalSettings