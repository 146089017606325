import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import QRInfo from "layouts/qrcodes/manager/new-qrcode/components/QRInfo";
import Media from "layouts/qrcodes/manager/new-qrcode/components/Media";
import Socials from "layouts/qrcodes/manager/new-qrcode/components/Socials";
import Pricing from "layouts/qrcodes/manager/new-qrcode/components/Pricing";
import globalSettings  from "context/globals"
import { useMaterialUIController, setCurrentQRCode } from "context";
import MDSnackbar from "components/MDSnackbar";
import { CircularProgress } from '@mui/material'

function getSteps(): string[] {
  return ["1. QR Code Info"];
}

function GetStepContent(stepIndex: number): JSX.Element {
  switch (stepIndex) {
    case 0:
      return <QRInfo />
    case 1:
      return <Media />
    case 2:
      return <Socials />
    case 3:
      return <Pricing />
    default:
      return null;
  }
}

function NewQRCode(): JSX.Element {
  const [activeStep, setActiveStep] = useState<number>(0);
  const [isToDisplaySnackBarSuccess, setIsToDisplaySnackBarSuccess] = useState<boolean>(false);
  const toggleisToDisplaySnackBarSuccess = () => setIsToDisplaySnackBarSuccess(!isToDisplaySnackBarSuccess);
  const steps = getSteps();
  const isLastStep: boolean = activeStep === steps.length - 1;
  const [controller, dispatch] = useMaterialUIController();
  const {
    currentQRCode,
    currentOrganization,
  } = controller;
  const handleNext = () => {
    setActiveStep(activeStep + 1)
  }
  const [isAttemptingToSave, setIsAttemptingToSave] = useState(false)
  const handleBack = () => setActiveStep(activeStep - 1);
  const handleSave = () => {
    setIsAttemptingToSave(true)
    fetch(`${globalSettings.apiBaseUrl}/api/qrcode/update`, {
      credentials: "include",
      method: "put",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        id: currentQRCode?.id,
        name: currentQRCode?.name,
        organizationId: currentOrganization.id,
        type: 1, // URL
        token: currentQRCode?.token,
        value: currentQRCode?.value,
        description: currentQRCode?.description,
        isDynamic: currentQRCode?.isDynamic,
        symbolUnicode: currentQRCode?.symbol,
        imageSizeRatio: currentQRCode?.imageSizeRatio,
        imageBordersize: currentQRCode?.imageBordersize,
        foregroundColorHex: currentQRCode?.foregroundColorHex,
        isToUseForegroundColor: currentQRCode?.isToUseForegroundColor,
        backgroundColorHex: currentQRCode?.backgroundColorHex,
        isToUseBackgroundColor: currentQRCode?.isToUseBackgroundColor,
        borderSize: currentQRCode?.borderSize,
        innerImageType: currentQRCode?.innerImageType,
        innerImageUrl: currentQRCode?.innerImageUrl,
        innerImageForegroundColorHex: currentQRCode?.innerImageForegroundColorHex,
        isToUseInnerImageForegroundColor: currentQRCode?.isToUseInnerImageBackgroundColor,
        innerImageBackgroundColorHex: currentQRCode?.innerImageBackgroundColorHex,
        isToUseInnerImageBackgroundColor: currentQRCode?.isToUseInnerImageBackgroundColor,
        innerImageBorderSize: currentQRCode?.innerImageBorderSize,
        innerImageSymbolUnicode: currentQRCode?.innerImageSymbolUnicode,
        innerImageSizeRatio: currentQRCode?.innerImageSizeRatio
       })
    })
      .then(Response => Response.json())
      .then(response => {
        setIsAttemptingToSave(false)
        //alert(JSON.stringify(response))
        if(response.responseCode === 1000) {
          currentQRCode.id = response.content.id
          setCurrentQRCode(dispatch, currentQRCode)
          setIsToDisplaySnackBarSuccess(true)
        } else {
          alert("Unable to create QR Code.")
        }
      })
  }
  
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={1} mb={1}>
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={8}>
            <MDBox mt={1} mb={2} textAlign="center">
              <MDBox mb={1}>
                <MDTypography variant="h3" fontWeight="bold">
                  {currentQRCode?.id === 0 ? 'Add' : 'Edit'} QR Code
                </MDTypography>
              </MDBox>
            </MDBox>
            <Card>
              <MDBox p={2}>
                <MDBox>
                  <QRInfo />
                    <MDButton
                      variant="gradient"
                      color="dark"
                      onClick={handleSave}
                    >
                      {isAttemptingToSave ? <>Save&nbsp;<CircularProgress size={20} sx={{ color: "#fff" }}/></> : "Save"}
                    </MDButton>
                  </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
      <MDSnackbar
        color="success"
        icon="notifications"
        title="QQWIK Portal"
        content={`QR Code has been saved.`}
        dateTime=""
        open={isToDisplaySnackBarSuccess}
        close={toggleisToDisplaySnackBarSuccess}
        autoHideDuration={3000}
      />
    </DashboardLayout>
  );
}

export default NewQRCode;
