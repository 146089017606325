import { useState, useEffect, ReactNode } from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import breakpoints from "assets/theme/base/breakpoints";
import burceMars from "assets/images/bruce-mars.jpg";
import backgroundImage from "assets/images/bg-profile.jpeg";
import globalSettings  from "context/globals"
import { useMaterialUIController, setCurrentAccount } from "context";
import Gravatar from "react-gravatar"

function Header({ children }: { children?: ReactNode }): JSX.Element {
  const [controller, dispatch] = useMaterialUIController();
  const {
    iToken,
    currentAccount
  } = controller;
  const [tabsOrientation, setTabsOrientation] = useState<"horizontal" | "vertical">("horizontal");
  const [tabValue, setTabValue] = useState(0);
  
  const loadAccountProfile = () => {
    //setIsAttemptingToSignIn(true)
    //setDidSigninFail(false)
    fetch(`${globalSettings.apiBaseUrl}/api/account/profile?itoken=${encodeURIComponent(iToken)}`, {
        credentials: "include",
        method: "get",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      })
        .then(Response => Response.json())
        .then(response => {
          //alert(JSON.stringify(response))
          //setIsAttemptingToSignIn(false)
          if(response.responseCode === 1000) {
            console.log(`response.responseCode: ${response.responseCode}`)
            setCurrentAccount(dispatch, response.content)
          } else {
            //setDidSigninFail(true)
            //setiTokenCookie(response.content?.loginToken)
            //setiToken(dispatch, response.content?.loginToken)
            //setDidSigninFail(false)   
          }
        })          
    }
  useEffect( () => {
      loadAccountProfile()
    }, [])

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event: any, newValue: any) => setTabValue(newValue);

  return (
    <MDBox position="relative" mb={5}>
      <MDBox
        display="flex"
        alignItems="center"
        position="relative"
        minHeight="18.75rem"
        borderRadius="xl"
        sx={{
          backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.info.main, 0.6),
              rgba(gradients.info.state, 0.6)
            )}, url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "50%",
          overflow: "hidden",
        }}
      />
      <Card
        sx={{
          position: "relative",
          mt: -8,
          mx: 3,
          py: 2,
          px: 2,
        }}
      >
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            {/*<MDAvatar src={burceMars} alt="profile-image" size="xl" shadow="sm" /> */}
            <Gravatar
              email={currentAccount.email}
              //className={classes.avatarImg}
              default="mp"
              size={80}
            />
          </Grid>
          <Grid item>
            <MDBox height="100%" mt={0.5} lineHeight={1}>
              <MDTypography variant="h5" fontWeight="medium">
                {currentAccount.firstName} {currentAccount.lastName}
              </MDTypography>
              <MDTypography variant="button" color="text" fontWeight="regular">
                {currentAccount.title}
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4} sx={{ ml: "auto" }}>
            <AppBar position="static">
              <Tabs orientation={tabsOrientation} value={tabValue} onChange={handleSetTabValue}>
                <Tab
                  label="App"
                  icon={
                    <Icon fontSize="small" sx={{ mt: -0.25 }}>
                      home
                    </Icon>
                  }
                />
                <Tab
                  label="Message"
                  icon={
                    <Icon fontSize="small" sx={{ mt: -0.25 }}>
                      email
                    </Icon>
                  }
                />
                <Tab
                  label="Settings"
                  icon={
                    <Icon fontSize="small" sx={{ mt: -0.25 }}>
                      settings
                    </Icon>
                  }
                />
              </Tabs>
            </AppBar>
          </Grid>
        </Grid>
        {children}
      </Card>
    </MDBox>
  );
}

// Declaring default props for Header
Header.defaultProps = {
  children: "",
};

export default Header;
