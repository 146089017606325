import { useState } from "react"
import { useSearchParams } from "react-router-dom";
import { Link } from "react-router-dom"
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import bgImage from "assets/images/openai/image4.jpg";
import globalSettings  from "context/globals"
import MDSnackbar from "components/MDSnackbar";
import MDAlert from "components/MDAlert";
import InputAdornment from '@mui/material/InputAdornment'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import IconButton from '@mui/material/IconButton'

function ChangePassword(): JSX.Element {
  const [searchParams] = useSearchParams()
  const [passwordResetCode, setPasswordResetCode] = useState(searchParams.get("resetcode"))
  const [isAttemptingToValidatePasswordResetCode, setIsAttemptingToValidatePasswordResetCode] = useState(false)
  const [isToDisplaySnackBarError, setIsToDisplaySnackBarError] = useState(false);
  const [isToDisplaySnackBarSuccess, setIsToDisplaySnackBarSuccess] = useState(false);
  const toggleisToDisplaySnackBarError = () => setIsToDisplaySnackBarError(!isToDisplaySnackBarError);
  const [isToShowPassword, setIsToShowPassword] = useState(false);
  const handleClickShowPassword = () => setIsToShowPassword((isToShow) => !isToShow);
  const [newPassword, setNewPassword] = useState('')
  const [didValidatePasswordResetCodeFail, setDidValidatePasswordResetCodeFail] = useState(false)
  
  const handleSetPassword = (event : any) => {
    //setDidSigninFail(false)
    setNewPassword(event.target.value)
  }
  const handleKeyDown = (e : any) => {
    if(e.keyCode === 13) { // enter key
        handleValidatePasswordResetCodeClick()
    }
  }
  const handleValidatePasswordResetCodeClick = () => {
    console.log('handleValidatePasswordResetCodeClick')
    setIsAttemptingToValidatePasswordResetCode(true)
    setIsToDisplaySnackBarSuccess(false)
    setIsToDisplaySnackBarError(false)
    //setDidSigninFail(false)
    fetch(`${globalSettings.apiBaseUrl}/api/account/validatepasswordresetcode`, {
        credentials: "include",
        method: "put",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          passwordResetCode,
          newPassword
        })
      })
        .then(Response => Response.json())
        .then(response => {
          setIsAttemptingToValidatePasswordResetCode(false)
          if(response.responseCode === 1000) {
            setIsToDisplaySnackBarSuccess(true)
          } else {
            setIsToDisplaySnackBarError(true)  
          }
        })          
    }

  
  return (
    <CoverLayout coverHeight="50vh" image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          py={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
            Set new password
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
          Define a new password.
          </MDTypography>  
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDInput
                type={isToShowPassword ? 'text' : 'password'}
                label="Password"
                variant="standard"
                fullWidth
                placeholder="************"
                InputLabelProps={{ shrink: true }}
                onChange={handleSetPassword}
                onKeyDown={handleKeyDown}
                InputProps={newPassword.length > 0 && {
                  endAdornment: 
                  <InputAdornment position="end" sx={{padding:"20px"}}>
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {isToShowPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                  }}
              />
            <MDBox mt={4} mb={1}>      
                <MDButton variant="gradient" color="info" fullWidth onClick={handleValidatePasswordResetCodeClick} disabled={isAttemptingToValidatePasswordResetCode}>
                    Save
                </MDButton>
                {didValidatePasswordResetCodeFail &&
                    <MDAlert color="error" dismissible>New password save failed</MDAlert>
                }
            </MDBox>
                <MDTypography
                    component={Link}
                    to="/sign-in"
                    variant="button"
                    color="info"
                    fontWeight="medium"
                    textGradient
                  >
                Back to sign in
              </MDTypography>
            {isToDisplaySnackBarError && (
              <MDBox mt={6} mb={1}>
                <MDAlert color="error">Sorry, but the password reset code is invalid.</MDAlert>
              </MDBox>
            )}
            {isToDisplaySnackBarSuccess && (
              <MDBox mt={6} mb={1}>
                <MDAlert color="success">New password has been set.</MDAlert>
              </MDBox>
            )}
          </MDBox>
        </MDBox>
      </Card>
      <MDSnackbar
        color="error"
        icon="notifications"
        title="Set Your New Password"
        content="Sorry, but the password reset code is invalid."
        dateTime=""
        open={isToDisplaySnackBarError}
        close={toggleisToDisplaySnackBarError}
      />
    </CoverLayout>
  );
}

export default ChangePassword;
