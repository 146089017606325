import Enums from "./Enums"
import TimeAgo from 'react-timeago'

const Helpers = {
    CookieExpiration: {
        OneDay: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
        OneWeek: new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000),
        OneMonth: new Date(new Date().getTime() + 30 * 24 * 60 * 60 * 1000),
    },
    RenderFriendlyQRTypeName: qrTypeId => {
        switch (qrTypeId) {
            case Enums.QRCodeTypes.SMS:
                return "SMS"
            case Enums.QRCodeTypes.TEXT:
                return "Text"
            case Enums.QRCodeTypes.URL:
                return "URL"
            case Enums.QRCodeTypes.VCARD:
            default:
            return "n/a"
        }
    },
    RenderAuditInfoCell: cell => {
        let created
        let modified
        created = cell ? (
          <span>
            created{" "}
            <TimeAgo
              date={cell.createdOn}
              title={Helpers.PrettyDateTimeinPacificTimeZone(
                cell.createdOn
              )}
            />{" "}
            by {cell.creator.username}
          </span>
        ) : (
          ""
        )
        modified = cell ? (
          <span>
            modified{" "}
            <TimeAgo
              date={cell.modifiedOn}
              title={Helpers.PrettyDateTimeinPacificTimeZone(
                cell.modifiedOn
              )}
            />{" "}
            by {cell.modifier.username}
            <br />
          </span>
        ) : (
          ""
        )
        return (
          <div>
            {modified}
            {created}
          </div>
        )
      },
      PrettyDateTimeinPacificTimeZone: datetimeString => {
        const dateTimeOptions = {
          weekday: "long",
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
          hour12: true,
          timeZone: "America/Los_Angeles",
          timeZoneName: "short"
        }
        if (!datetimeString) {
          return null
        }
        return new Intl.DateTimeFormat("en-US", dateTimeOptions).format(
          new Date(datetimeString)
        )
      },
      TruncateString: stringToTruncate => {
        return stringToTruncate?.length > 17 ? stringToTruncate?.substring(0, 17) + "..." : stringToTruncate;
    }
}

export default Helpers