import { useState, useEffect } from "react"
import { useSearchParams } from "react-router-dom";
import Grid from "@mui/material/Grid"
import Autocomplete from "@mui/material/Autocomplete";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDEditor from "components/MDEditor";
import MDInput from "components/MDInput";
import FormField from "layouts/ecommerce/products/new-product/components/FormField";
import { useMaterialUIController, setCurrentQRCode } from "context";
import Checkbox from "@mui/material/Checkbox"
import globalSettings  from "context/globals"
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import QRInfoColorPicker from "./QRInfoColorPicker"
import QRInfoSymbolsChooser from "./QRInfoSymbolsChooser"
import Slider from '@mui/material/Slider'
import Fullscreen from "@mui/icons-material/Fullscreen"
import CloudDownload from "@mui/icons-material/CloudDownload"
import MDButton from "components/MDButton";

interface qrInfoProps {
 // form?: string;
 // disabled?: boolean;
 // icon?: React.ReactElement<SvgIconProps>;
 // type: string;
 // onClick: () => void;
 // text: string;
 qrCodeId?: number
 someValue?: string
 sendDataToParent?: (arg0: number) => void
}

function QRInfo(props : qrInfoProps): JSX.Element {
  const [innerImageType, setInnerImageType] = useState<number>(1)
  const [qrCodeType, setQRCodeType] = useState<string>("URL")
  const [valueLabel, setValueLabel] = useState<string>("Destination URL")
  const [controller, dispatch] = useMaterialUIController();
  const {
    currentQRCode
  } = controller;
  const handleValueChange = (event : any) => {
    currentQRCode.value = event.target.value
    setCurrentQRCode(dispatch, currentQRCode)
  }
  const handleForegroundColorHexChange = (colorKey: string, value: any) => {
    currentQRCode.foregroundColorHex = value
    setCurrentQRCode(dispatch, currentQRCode)
  }
  const handleIsToUseForegroundColorChange = (value: boolean) => {
    currentQRCode.isToUseForegroundColor = value
    setCurrentQRCode(dispatch, currentQRCode)
  }
  const handleBackgroundColorHexChange = (colorKey: string, value: any) => {
    currentQRCode.backgroundColorHex = value
    setCurrentQRCode(dispatch, currentQRCode)
  }
  const handleIsToUseBackgroundColorChange = (value : boolean) => {
    currentQRCode.isToUseBackgroundColor = value
    setCurrentQRCode(dispatch, currentQRCode)
  }
  // inner image handlers
  const handleIsToUseInnerImageChange = (event: any, value: boolean) => {
    currentQRCode.isToUseInnerImage = value
  }
  const handleInnerImageUrlChange = (event : any) => {
    currentQRCode.innerImageUrl = event.target.value
    setCurrentQRCode(dispatch, currentQRCode)
  }
  const handleInnerImageSizeRatioChange = (event : any) => {
    let value = event.target.value
    if(value === "") {
      value = null
    } else {
      value = Number(value)
    }
    currentQRCode.innerImageSizeRatio = value
    setCurrentQRCode(dispatch, currentQRCode)
  }
  const handleInnerImageBorderSizeChange = (event : any) => {
    let value = event.target.value
    if(value === "") {
      value = null
    } else {
      value = Number(value)
    }
    currentQRCode.innerImageBorderSize = value
    setCurrentQRCode(dispatch, currentQRCode)
  }
  const handleImageSizeRatioChange = (event : any) => {
    currentQRCode.imageSizeRatio = event.target.value
    setCurrentQRCode(dispatch, currentQRCode)
  }
  const handleImageBorderChange = (event : any) => {
    currentQRCode.imageBorder = event.target.value
    setCurrentQRCode(dispatch, currentQRCode)
  }
  const handleIsDynamicCheckboxChange = (event : any) => {
    currentQRCode.isDynamic = !currentQRCode.isDynamic
    setCurrentQRCode(dispatch, currentQRCode)
  }
  const handleNameChange = (event : any) => {
    currentQRCode.name = event.target.value
    setCurrentQRCode(dispatch, currentQRCode)
  }
  const handleQRTypeOnChange = (event: any, newValue: any) => {
    setQRCodeType(newValue.id)
  }
  const handleInnerImageTypeOnChange = (event: any, newValue: any) => {
    currentQRCode.innerImageType = newValue.id
    setInnerImageType(newValue.id)
    setCurrentQRCode(dispatch, currentQRCode)
    //setInnerImageType(newValue.id)
  }
  const handleBorderSizeChange = (event : any) => {
    currentQRCode.borderSize = event.target.value
    setCurrentQRCode(dispatch, currentQRCode)
  }
  const handleDescriptionChange = (event : any) => {
    currentQRCode.description = event.target.value
    setCurrentQRCode(dispatch, currentQRCode)
  }
  const handleInnerImageSymbolUnicodeChange = (symbol : string, value: any) => {
    currentQRCode.innerImageSymbolUnicode = value
    setCurrentQRCode(dispatch, currentQRCode)
  }
  const handleInnerImageForegroundColorHexChange = (colorKey: string, value: any) => {
    currentQRCode.innerImageForegroundColorHex = value
    setCurrentQRCode(dispatch, currentQRCode)
  }
  const handleIsToUseInnerImageForegroundColorChange = (value : boolean) => {
    currentQRCode.isToUseInnerImageForegroundColor = value
    setCurrentQRCode(dispatch, currentQRCode)
  }
  const handleInnerImageBackgroundColorHexChange = (colorKey: string, value: any) => {
    currentQRCode.innerImageBackgroundColorHex = value
    setCurrentQRCode(dispatch, currentQRCode)
  }
  const handleIsToUseInnerImageBackgroundColorChange = (value : boolean) => {
    currentQRCode.isToUseInnerImageBackgroundColor = value
    setCurrentQRCode(dispatch, currentQRCode)
  }
 
  const [searchParams] = useSearchParams();

  const buildQRCodeGenerateUrl = (isDownload? : boolean) => {
    if(typeof isDownload == 'undefined') {
      isDownload = false
    }
    let url = `${globalSettings.apiBaseUrl}/api/qrcode/generate?text=${encodeURIComponent(currentQRCode?.value)}&isdynamic=${currentQRCode?.isDynamic ? 'true' : 'false'}&isDownload=${isDownload}`
    if(currentQRCode?.token) {
      url += `&token=${currentQRCode?.token}`
    }
    if(currentQRCode?.isToUseForegroundColor && currentQRCode?.foregroundColorHex) {
      url += `&foregroundcolorhex=${currentQRCode?.foregroundColorHex}`
    }
    if(currentQRCode?.isToUseBackgroundColor && currentQRCode?.backgroundColorHex) {
      url += `&backgroundcolorhex=${currentQRCode?.backgroundColorHex}`
    }
    if(currentQRCode?.borderSize) {
      url += `&borderSize=${currentQRCode?.borderSize}`
    }
    if(currentQRCode?.imageBorder) {
      url += `&imageBorder=${encodeURIComponent(currentQRCode?.imageBorder)}`
    }
    // inner image stuff
    if(currentQRCode?.innerImageType) {
      url += `&innerImageType=${currentQRCode?.innerImageType}`
    }
    if(currentQRCode?.isToUseInnerImageForegroundColor && currentQRCode?.innerImageForegroundColorHex) {
      url += `&innerImageForegroundColorHex=${currentQRCode?.innerImageForegroundColorHex}`
    }
    if(currentQRCode?.isToUseInnerImageBackgroundColor && currentQRCode?.innerImageBackgroundColorHex) {
      url += `&innerImageBackgroundColorHex=${currentQRCode?.innerImageBackgroundColorHex}`
    }
    if(currentQRCode?.innerImageBorderSize) {
      url += `&innerImageBorderSize=${currentQRCode?.innerImageBorderSize}`
    }
    if(currentQRCode?.innerImageSizeRatio) {
      url += `&innerImageSizeRatio=${currentQRCode?.innerImageSizeRatio}`
    }
    if(currentQRCode?.innerImageUrl) {
      url += `&innerImageUrl=${encodeURIComponent(currentQRCode?.innerImageUrl)}`
    }
    if(currentQRCode?.innerImageSymbolUnicode) {
      url += `&innerImageSymbolUnicode=${currentQRCode?.innerImageSymbolUnicode}`
    }             
    return url
  }

  const retrieveQRCode = (qrCodeId : any) => {
    fetch(`${globalSettings.apiBaseUrl}/api/qrcode/retrieve?qrcodeid=${qrCodeId}`, {
      credentials: "include",
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    })
      .then(Response => Response.json())
      .then(response => {
        //alert(JSON.stringify(response))
        if(response.responseCode === 1000) {
          setCurrentQRCode(dispatch, response.content)
         // alert(`Successfully retrieve QR Code Id: ${currentQRCode?.id}`)
        } else {
          alert("Unable to retrieve QR Code.")
        }
      })
  }

  const qrTypeOptions : any = [
    {id: 1, name: "URL"},
    {id: 2, name: "vCard"},
    {id: 3, name: "SMS"},
    {id: 4, name: "SMS & message"},
    {id: 5, name: "Phone number"},
    {id: 6, name: "Email"},
    {id: 7, name: "Email & subject & body"},
    {id: 8, name: "Geo location"}
  ]

  const innerImageTypeOptions : any = [
    {id: 0, name: "None"},
    {id: 1, name: "Symbol"},
    {id: 2, name: "Image URL"}
  ]

  useEffect( () => {
    if(parseInt(searchParams.get("qrcodeid")) > 0 ) {
      retrieveQRCode(parseInt(searchParams.get("qrcodeid")))
    } else {
      setCurrentQRCode(dispatch, {...currentQRCode, isDynamic : searchParams.get("isdynamic") === "1" ? true : false})
    }
  }, [])

  useEffect( () => {
    setInnerImageType(currentQRCode.innerImageType)
  }, currentQRCode?.innerImageType)

  return (
    <>
    <MDBox>
      <MDBox>
      {/*}  searchParams.get("qrcodeid") = {searchParams.get("qrcodeid")} <br/>
        searchParams.get("isdynamic") = {searchParams.get("isdynamic")}<br/>
        Boolean(searchParams.get("isdynamic")) = {searchParams.get("isdynamic") ? "true" : "false"}<br/>
        currentQRCode = {JSON.stringify(currentQRCode)}<br/>
        currentQRCode?.isDynamic = {currentQRCode?.isDynamic ? "true" : "false"}<br/>
  */}
  
      <Grid container spacing={3}>
          <Grid item xs={12} mb={2}>
            <FormField type="text" label={valueLabel} value={currentQRCode?.value || ''} onChange={handleValueChange}/>
          </Grid>
          <Grid item xs={12} mb={2}>
            <FormField type="text" label="Name" value={currentQRCode?.name || ''} onChange={handleNameChange} />
          </Grid>
          <Grid item xs={12} mb={2}>
            <FormField type="text" label="Description" value={currentQRCode?.description || ''} onChange={handleDescriptionChange} multiline={true} rows={3}/>
          </Grid>
          {/*
          <Grid item sm={2}>
            <FormField type="text" label="Token" value={currentQRCode?.token || ''} readonly/>
          </Grid>
          
          <Grid item xs={2}>
            <MDBox mb={2} display="inline-block">
                <MDTypography
                  component="label"
                  variant="button"
                  fontWeight="regular"
                  color="text"
                  textTransform="capitalize"
                >
                  Is Dynamic?
                </MDTypography>
              </MDBox>
              <Checkbox onChange={handleIsDynamicCheckboxChange} checked={currentQRCode?.isDynamic}/>
          </Grid>
          */}
        </Grid>
        {/* !currentQRCode?.isDynamic &&
        <>
        <MDBox mb={3}>
          <MDBox sm={6} display="inline-block">
            <MDTypography
              component="label"
              variant="button"
              fontWeight="regular"
              color="text"
              textTransform="capitalize"
            >
              Type
            </MDTypography>
          </MDBox>
          <Autocomplete
            defaultValue="URL"
            options={qrTypeOptions}
            renderOption={(props, item : any) => (
              <li {...props} key={item.id}>
                {item.name}
              </li>
            )}
            getOptionLabel={option =>
              option
                ? option.name !== null
                  ? `${option.name}`
                  : ""
                : ""
            }
            isOptionEqualToValue={(option, value) =>
              option === value
            }
            renderInput={(params) => <MDInput {...params} variant="standard" />}
            onChange={(event, newValue) => handleQRTypeOnChange(event, newValue)}
            disableClearable={true}
          />
        </MDBox>
        </> */}
            
        </MDBox>
      <MDBox mt={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <MDBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <MDTypography component="label" variant="button" fontWeight="regular" color="text">
                {currentQRCode?.isDynamic ? `Dynamic` : `Static`} QR Code
              </MDTypography>
            </MDBox>
            <div style={{backgroundColor: "grey"}}>
              <img style={{height: "200px", width:"200px", margin: "auto", display: "block", padding:"10px"}} src={buildQRCodeGenerateUrl()} />
            </div>
            <MDBox xs={12}>
            <a
                  href={buildQRCodeGenerateUrl()}
                  target="_new"
                  title="View QR Code Fullscreen"
              >
                <MDButton variant="text" simple color="info" className="info">
                  <Fullscreen />&nbsp;View
                </MDButton>
              </a>
              <a
                  href={buildQRCodeGenerateUrl(true)}
                  target="_new"
                  title="Download QR Code"
              >
                <MDButton variant="text" simple color="info" className="info">
                  <CloudDownload />&nbsp;Download
                </MDButton>
              </a>
            
            </MDBox>
          </Grid>
          <Grid item xs={12} sm={8}>
          <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                Colors
              </AccordionSummary>
              <AccordionDetails>
              <MDTypography component="label" variant="button" fontWeight="regular" color="text">
                Foreground Color?
              </MDTypography>
            
                <QRInfoColorPicker 
                  title="Foreground"
                  onColorPickerChange={handleForegroundColorHexChange}
                  onIsToUseColorChange={handleIsToUseForegroundColorChange}
                  hexColor={currentQRCode?.foregroundColorHex}
                  isSelected={currentQRCode?.isToUseForegroundColor}
                />

              <MDTypography component="label" variant="button" fontWeight="regular" color="text">
                Background Color?
              </MDTypography>
                <QRInfoColorPicker 
                  title="Background"
                  onColorPickerChange={handleBackgroundColorHexChange} 
                  onIsToUseColorChange={handleIsToUseBackgroundColorChange}
                  hexColor={currentQRCode?.backgroundColorHex}
                  isSelected={currentQRCode?.isToUseBackgroundColor}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                Borders
              </AccordionSummary>
              <AccordionDetails>
                <Grid item xs={2}>
                  <FormField type="number" min="0" max="10" label="Border Size" value={currentQRCode?.borderSize} onChange={handleBorderSizeChange} />
                </Grid>
              </AccordionDetails>
            </Accordion>
           {/* <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                Dimensions & Format
              </AccordionSummary>
              <AccordionDetails>
                Height X Width
                Image format (codec, png, jpg, svg, etc.)
              </AccordionDetails>
            </Accordion>
      */}
            <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                Inner Image
              </AccordionSummary>
              <AccordionDetails>
              <MDBox mb={3}>
          <MDBox sm={6} display="inline-block">
            <MDTypography
              component="label"
              variant="button"
              fontWeight="regular"
              color="text"
              textTransform="capitalize"
            >
              Type
            </MDTypography>
          </MDBox>
          <Autocomplete
            value={innerImageTypeOptions.find ((i : any) => i.id === innerImageType)}
            options={innerImageTypeOptions}
            renderOption={(props, item : any) => (
              <li {...props} key={item.id}>
                {item.name}
              </li>
            )}
            getOptionLabel={option =>
              option
                ? option.name !== null
                  ? `${option.name}`
                  : ""
                : ""
            }
            isOptionEqualToValue={(option, value) =>
              option === value
            }
            renderInput={(params) => <MDInput {...params} variant="standard" />}
            onChange={(event, newValue) => handleInnerImageTypeOnChange(event, newValue)}
            disableClearable={true}
          />
        </MDBox>
        {currentQRCode?.innerImageType === 1 &&
              <QRInfoSymbolsChooser symbolUnicode={currentQRCode?.innerImageSymbolUnicode} hexColor={currentQRCode?.isToUseInnerImageForegroundColor && currentQRCode?.innerImageForegroundColorHex} onSymbolChange={handleInnerImageSymbolUnicodeChange}/>
        }    
          {currentQRCode?.innerImageType === 2 &&
                    <Grid item>
                    <FormField type="text" label="External Image Url" value={currentQRCode?.innerImageUrl} onChange={handleInnerImageUrlChange}/>
                  </Grid>
          
          }
          {currentQRCode?.innerImageType !== 0 &&
          <>
                <Grid item mt={2}>
                  <FormField type="number" label="Image Size Ratio" value={currentQRCode?.innerImageSizeRatio} onChange={handleInnerImageSizeRatioChange}/>
                </Grid>
                <Grid item mt={2}>
                  <FormField type="number" label="Image Border" value={currentQRCode?.innerImageBorderSize} onChange={handleInnerImageBorderSizeChange}/>
                </Grid>
                <MDTypography component="label" variant="button" fontWeight="regular" color="text">
                Use Foreground Color?
                </MDTypography>
            
                <QRInfoColorPicker 
                  title="Foreground"
                  onColorPickerChange={handleInnerImageForegroundColorHexChange}
                  onIsToUseColorChange={handleIsToUseInnerImageForegroundColorChange}
                  hexColor={currentQRCode?.innerImageForegroundColorHex}
                  isSelected={currentQRCode?.isToUseInnerImageForegroundColor}
                />

              <MDTypography component="label" variant="button" fontWeight="regular" color="text">
                Use Background Color?
                </MDTypography>
            
                <QRInfoColorPicker 
                  title="Background"
                  onColorPickerChange={handleInnerImageBackgroundColorHexChange}
                  onIsToUseColorChange={handleIsToUseInnerImageBackgroundColorChange}
                  hexColor={currentQRCode?.innerImageBackgroundColorHex}
                  isSelected={currentQRCode?.isToUseInnerImageBackgroundColor}
                />
                </>
          }
              </AccordionDetails>
            </Accordion>
          </Grid>
          </Grid>
      </MDBox>
    </MDBox>
    </>
  );
}

export default QRInfo;
