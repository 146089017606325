/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Countries flags
import US from "assets/images/icons/flags/US.png";
import DE from "assets/images/icons/flags/DE.png";
import GB from "assets/images/icons/flags/GB.png";
import BR from "assets/images/icons/flags/BR.png";

const salesTableData = [
  {
    country: [US, "united state"],
    scans: "2,556",
    users: "2,456",
    "daily change": "+29.9%",
  },
  {
    country: [DE, "germany"],
    scans: "3,910",
    users: "3,900",
    "daily change": "-40.22%",
  },
  {
    country: [GB, "great britain"],
    scans: 140,
    users: 135,
    "daily change": "-23.44%",
  },
  {
    country: [BR, "brasil"],
    scans: 562,
    users: "562",
    "daily change": "-32.14%" },
];

export default salesTableData;
