/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const calendarEventsData = [
  {
    title: "All day conference",
    start: "2021-08-01",
    end: "2021-08-01",
    className: "success",
  },

  {
    title: "Meeting with Mary",
    start: "2021-08-03",
    end: "2021-08-03",
    className: "info",
  },

  {
    title: "Cyber Week",
    start: "2021-08-04",
    end: "2021-08-04",
    className: "warning",
  },

  {
    title: "Winter Hackaton",
    start: "2021-08-05",
    end: "2021-08-05",
    className: "error",
  },

  {
    title: "Digital event",
    start: "2021-08-09",
    end: "2021-08-11",
    className: "warning",
  },

  {
    title: "Marketing event",
    start: "2021-08-12",
    end: "2021-08-12",
    className: "primary",
  },

  {
    title: "Dinner with Family",
    start: "2021-08-21",
    end: "2021-08-21",
    className: "error",
  },

  {
    title: "Black Friday",
    start: "2021-08-25",
    end: "2021-08-25",
    className: "info",
  },
];

export default calendarEventsData;
